.edgebutton {
    cursor: pointer;
    background: none;
    outline: none;
    border: none;
}

.edgebutton img {
    width: 16px;
    height: 16px;
}
  
  .edgebutton img:hover {
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
  }