.OpenDashboardButton {
    display:none;
    background: none;
	color: var(--workspace-primary-color-1);
	border: none;
	padding-top: 7px;
    padding-bottom: 7px;
    padding-right: 10px;
    padding-left: 10px;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    background-color:  var(--workspace-secondary-color-1);
    border-radius: 50px;
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: normal;
    text-decoration: none;
    margin-right: 10px;
    white-space: nowrap;
    border:1px solid transparent;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.OpenDashboardButton:hover {
    border-color: var(--workspace-secondary-color-1);
    background-color: transparent;
    color: var(--workspace-secondary-color-1);
    cursor:pointer!important;
}

  
  @media only screen and (min-width: 1025px) {
    .OpenDashboardButton {
        display:block;
    }
  }