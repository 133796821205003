
.OrgEmptyStateContainer {
    margin-top: 40px;
    display: flex;
    background-color: var(--neutral-color-9);
    flex-direction: column;
    align-items: center;
    padding-top: 15vh;
    padding-bottom: 15vh;
}

.OrgEmptyStateContainer .Title {
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: normal;
    color: var(--neutral-color-5);
    margin-top: 5px;
    margin-bottom: 0;
}

.OrgEmptyStateContainer .ButtonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 40px;
}

.OrgEmptyStateContainer .ButtonsContainer p {
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: normal;
    color: var(--neutral-color-5);
    margin-bottom: 10px;
}

.OrgEmptyStateContainer .ButtonsContainer .Button {
    margin-left: 25px;
    margin-right: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}