.UserActivityIndicator .Active {
    border: 1px solid var(--workspace-primary-color-3);
    border-radius: 50px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #33C35326;
}

.UserActivityIndicator .Active span {
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: normal;
    color: var(--workspace-primary-color-3);
}

.UserActivityIndicator .InvSent {
    border: 1px solid #B9BBBB;
    border-radius: 50px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #D3D5D426;
}

.UserActivityIndicator .InvSent span {
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: normal;
    color: #505050;
}

.UserActivityIndicator .Inactive {
    border: 1px solid #971B1B;
    border-radius: 50px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #D7343426;
}

.UserActivityIndicator .Inactive span {
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: normal;
    color: #971B1B;
}