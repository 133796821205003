.VideoSourceOptions {
    padding-left: 5px;
    padding-right: 5px;
}

.VideoSourceOptions .PlayerContainer {
    width: 100%;
    position: relative;
    margin-top: 20px;
}

.VideoSourceOptions .TimePickersContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
}

.VideoSourceOptions .SelectSourceButton {
    width: 100%;
    margin-top: 5px;
}

.VideoSourceOptions .Section {
    margin-bottom: 10px;
}