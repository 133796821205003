.AddLearnersContainer .HeaderContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.AddLearnersContainer .Footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 30px;
}

.AddLearnersContainer .Footer .Cancel {
    margin-right: 10px;
}

.EmailInput{
    font-family: var(--font-family);
    font-size: 12px;
    color: var(--neutral-color-1)59;
    font-weight: normal;
    padding-left: 20px;
    padding-bottom: 15px;
    padding-top: 15px;
    margin-top: 20px;
    margin-bottom: 30px;
}

.EmailInput h3{
    font-family: var(--font-family);
    font-size: 12px;
    color: #555555;
    font-weight: bold;
    margin-bottom: 10px;
}

.EmailInput input{
    appearance: none;
    outline: none;
    border: none;
    background-color: transparent;
    font-family: var(--font-family);
    font-size: 12px;
    color: var(--neutral-color-1);
    font-weight: normal;
    border-bottom: 1px solid #efeae7;
    padding-left: 20px;
    padding-bottom: 15px;
    padding-top: 15px;
    width: 100%;
}

.EmailInput .EmailsSelected{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    appearance: none;
    outline: none;
    border: none;
    background-color: transparent;
    font-family: var(--font-family);
    font-size: 12px;
    color: var(--neutral-color-1);
    font-weight: normal;
    padding-left: 20px;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
}

.EmailInput span{
    cursor: pointer;
}

.AddLearnerContainer .EmailInput::placeholder {
    color: var(--neutral-color-1)59;
}

.AddLearnerContainer .EmailInstructionsDesign{
    display: flex;
    justify-content: flex-start;
    margin-top: 6px;
}