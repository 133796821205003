.PreviewPlayerContainer {
    width: 50vw;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.PreviewImageContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.PreviewImageContainer img {
    max-width: 40vw;
    object-fit: contain;
    max-height: 60vh;
}