.SelectSubscriptionContainer .SelectTitle {
    color: black;
    font-size: 20px;
    font-family: var(--font-family);
    font-weight: normal;
    text-align: center;
    margin-top: 50px;
}

.SubscriptionResult {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.SubscriptionResult h3 {
    font-family: var(--font-family);
    font-size: var(--font-size-h3);
    font-weight: bold;
    text-align: center;
}

.SubscriptionResult p {
    font-family: var(--font-family);
    font-size: var(--font-size-h5);
    font-weight: normal;
    margin-bottom: 4px;
    text-align: center;
}

.SubscriptionResult button {
    margin: 0 auto;
    display: block;
}

.SubscriptionResult h2 {
    font-family: var(--font-family);
    font-weight: bold;
    font-size: 32px;
}

.SelectSubscriptionContainer .SubscriptionPlansContainer {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
}

.SelectSubscriptionContainer .SubscriptionPlan {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 1rem;
    background-color: var(--workspace-primary-color-3);
    color: white;
    padding: 25px;
    border-radius: 5px;
    font-family: var(--font-family);
}

.SelectSubscriptionContainer .SubscriptionPlan .Features {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-left: 0;
}

.SelectSubscriptionContainer .SubscriptionPlan .Features span {
    background-color: var(--workspace-secondary-color-1);
    color: var(--workspace-primary-color-3);
    font-size: 8px;
    display: block;
    padding: 3px;
    border-radius: 10px;
    margin-right: 5px;
}

.SelectSubscriptionContainer .SubscriptionPlan .Features li::marker {
    display: none;
    content: '';
}

.SelectSubscriptionContainer .SubscriptionPlan .Features li {
    display: flex;
    align-items: center;
}

.SelectSubscriptionContainer .SubscriptionPlan h2 {
    font-size: 32px;
    font-weight: bold;
    margin: 0;
}

.SelectSubscriptionContainer .SubscriptionPlan p {
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 10px;
}

.SelectSubscriptionContainer .SubscriptionPlan span {
    font-size: 14px;
    font-weight: normal;
}


.SelectSubscriptionContainer .SubscriptionPlan h3 {
    font-size: 24px;
    font-weight: bold;
}

.SelectSubscriptionContainer .SubscriptionPlan h4 {
    font-size: 16px;
    font-weight: 200;
}

.SelectSubscriptionContainer .SubscriptionPlan h5 {
    font-size: 12px;
    font-weight: 200;
}

.SelectSubscriptionContainer .SubscriptionPlan .SelectSubButton {
    width: 100%;
    padding: 0.7rem;
    margin-top: 15px;
}

.TailorSubscriptionContainer {
    background-color: var(--neutral-color-9);
}

.TailorSubscriptionContainer .SubscriptionTailorTools {
    display: flex;
    flex-direction: column;
}

.TailorSubscriptionContainer .SubscriptionTailorTools .Header {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 30px;
}

.TailorSubscriptionContainer .SubscriptionTailorTools .Header h2 {
    font-family: var(--font-family);
    font-weight: bold;
    font-size: var(--font-size-h1);
    margin-bottom: 20px;
}

.TailorSubscriptionContainer .SubscriptionTailorTools .Header p {
    font-family: var(--font-family);
    font-weight: normal;
    font-size: var(--font-size-h4);
}

.TailorSubscriptionContainer .SubscriptionTailorTools .SelectSeatsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.TailorSubscriptionContainer .SubscriptionTailorTools .SelectSeatsContainer p {
    margin-bottom: 0;
    margin-top: 4px;
    color: var(--neutral-color-1);
}

.TailorSubscriptionContainer .SubscriptionTailorTools .SelectSeatsContainer .Input {
    width: 6rem;
    margin-right: 10px;
}

.TailorSubscriptionContainer .SubscriptionTailorTools .SelectSeatsContainer .Input input {
    font-size: var(--font-size-h4);
}

.TailorSubscriptionContainer .SubscriptionTailorTools .SeatsAmountDisclaimer {
    font-family: var(--font-family);
    font-weight: normal;
    font-size: var(--font-size-h6);
    color: var(--neutral-color-3);
    margin-top: 5px;
}

.TailorSubscriptionContainer .GradableSelectContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.TailorSubscriptionContainer .GradableSelectTitle {
    font-family: var(--font-family);
    font-weight: normal;
    font-size: var(--font-size-h3);
    color: var(--neutral-color-1);
    text-align: center;
    margin-top: 20px;
}

.TailorSubscriptionContainer .GradableSelectContainer .GradableSelected {
    background-color: #056260 !important;
    border: 3px solid var(--workspace-secondary-color-1) !important;
}

.TailorSubscriptionContainer .GradableSelectContainer .GradableCard {
    display: flex;
    flex-direction: column;
    padding: 40px;
    background-color: #5C5C5C;
    margin: 1.5rem;
    width: 34%;
    color: white;
    font-family: var(--font-family);
    filter: drop-shadow(0px 1px 14px rgba(0, 0, 0, 0.12)) drop-shadow(0px 5px 8px rgba(0, 0, 0, 0.14)) drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.20));
    border-radius: 5px;
    cursor: pointer;
    border: 3px solid #5C5C5C;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.TailorSubscriptionContainer .GradableSelectContainer .GradableCard .SelectedMarker {
    position: absolute;
    top: -12px;
    right: -12px;
    background-color: var(--workspace-secondary-color-1);
    font-size: 20px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
}

.TailorSubscriptionContainer .GradableSelectContainer .GradableCard p {
    font-family: var(--font-family);
    font-weight: bold;
    font-size: 20px;
    color: white;
    margin-bottom: 15px;
}

.TailorSubscriptionContainer .GradableSelectContainer .GradableCard .Features {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-left: 0;
}

.TailorSubscriptionContainer .GradableSelectContainer .GradableCard .Features span {
    background-color: var(--workspace-secondary-color-1);
    color: var(--workspace-primary-color-3);
    font-size: 8px;
    display: block;
    padding: 3px;
    border-radius: 10px;
    margin-right: 5px;
}

.TailorSubscriptionContainer .GradableSelectContainer .GradableCard .Features li::marker {
    display: none;
    content: '';
}

.TailorSubscriptionContainer .GradableSelectContainer .GradableCard .Features li {
    display: flex;
    align-items: center;
}


@media only screen and (max-width: 767px) {
    .TailorSubscriptionContainer .GradableSelectContainer .GradableCard {
        width: 100%;
        padding-top: 1rem;
        padding-bottom: 1rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .TailorSubscriptionContainer .GradableSelectContainer {
        flex-wrap: wrap;
        padding-bottom: 60%;
    }

    .TailorSubscriptionContainer .SubscriptionResultContainer {
        position: fixed;
        bottom: 0;
        background: #ebebeb;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .TailorSubscriptionContainer .SubscriptionResultContainer .SubscriptionResult {
        flex-direction: row;
        justify-content: space-around;
    }

    .TailorSubscriptionContainer .SubscriptionResultContainer .SubscriptionResult h3 {
        font-size: var(--font-size-h5);
    }

    .TailorSubscriptionContainer .SubscriptionResultContainer .SubscriptionResult h2 {
        font-size: var(--font-size-h3);
    }
}

@media only screen and (min-width: 767px) and (max-width: 989px) {
    .TailorSubscriptionContainer .GradableSelectContainer {
        flex-wrap: wrap;
        padding-bottom: 25%;
    }

    .TailorSubscriptionContainer .SubscriptionResultContainer {
        position: fixed;
        bottom: 0;
        background: #ebebeb;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    .TailorSubscriptionContainer .SubscriptionResultContainer .SubscriptionResult {
        flex-direction: row;
        justify-content: space-around;
    }
}