.EditorTooltopButton {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.EditorTooltopButton .AddButton {
    outline: none;
    border: none;
    background: none;
}

.EditorTooltipArrowDetail:before {
    content: "";
    width: 40px;
    position: absolute;
    height: 40px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 12px;
    z-index: 10;
    background: white;
    box-shadow: none;
    border: 0;
    border-radius: 5px;
}

.EditorTooltipArrowDetail {
    width: 35px;
    position: absolute;
    height: 24px;
    overflow: hidden;
    top: -16px;
}

.EditorTooltipContainer {
    position: absolute;
    width: auto;
    top: 140%;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    filter: drop-shadow(0px 1px 5px #D2D2D2);
}

.EditorTooltipContent {
    background-color: var(--neutral-color-10);
    border: 0 solid #f5f5f5e9;
    border-radius: 5px;
    font-family: var(--font-family);
    color:var(--neutral-color-1);
    right: 0px;
    line-height: normal;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 7px;
    padding-right: 7px;
    display: flex;
    flex-direction: row;
}

.EditorTooltipContainer button {
    outline: none;
    border: none;
    background: none;
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
}

.EditorTooltipContainer img {
    width: 22px;
    height: 22px;
}
