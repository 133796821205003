.ModalContainer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 110;
}

.ModalContainer .ModalContent {
    background-color: var(--neutral-color-10);
    border-radius: 5px;
    padding: 2rem 3rem;
    min-width: 60vw;
    position: relative;
    z-index: 200;
    max-height: 90vh;
    overflow-y: scroll;
}

.ModalContainer .ModalContent .CloseButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    outline: none;
    border: none;
}