.YesNoOptions p {
    font-family: var(--font-family);
    font-size: 12px;
    color: #555555;
    font-weight: bold;
    margin-bottom: 10px;
}

.YesNoOptions .SubtitleText {
    font-family: var(--font-family);
    font-size: 10px;
    font-weight: normal;
    color: var(--neutral-color-5);
}


.YesNoDisabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.YesNoOptions button:disabled {
    cursor: not-allowed;
}

.YesNoOptions .ButtonYes {
    margin-right: 8px;
}

.YesNoOptions .Options {
    display: flex;
    flex-direction: row;
}

.YesNoOptionsStandart button {
    background: none;
	color: var(--neutral-color-10);
	border: none;
	padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    background-color: var(--workspace-primary-color-3);
    border-radius: 50px;
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: normal;
    line-height: 14px;
}

.YesNoOptionsStandart .GreyedOut {
    border: 1px solid #E2E2E2;
    background-color: #F6F6F680;
    color: #97979780;
}

.YesNoOptionsIcon button {
    background: none;
	color: #DBDBDB;
	border: none;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    border-radius: 50px;
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: bold;
    line-height: 14px;
}

.YesNoOptionsIcon .GreyedOut {
    color: #97979780 !important;
}

.YesNoOptionsIcon .ButtonYes {
    color: var(--success-color-1);
}

.YesNoOptionsIcon .ButtonNo span {
    color: var(--error-color-1);
    font-weight: bold;
    font-size: 12px;
}

.YesNoOptionsIcon .GreyedOut span {
    color: #97979780 !important;
}

.YesNoOptionsBox button {
    background: none;
	color: var(--neutral-color-10);
	border: none;
	padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    background-color: var(--workspace-primary-color-3);
    border-radius: 3px;
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: normal;
    line-height: 14px;
    min-width: 4rem;
}

.YesNoOptionsBox .GreyedOut {
    border: 1px solid #E2E2E2;
    background-color: #F6F6F680;
    color: #97979780;
}
