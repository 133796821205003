.BoxImagePreview .Title {
    font-family: var(--font-family);
    font-size: 12px;
    color: #555555;
    font-weight: bold;
    margin-bottom: 5px;
}

.BoxImagePreview .PreviewContainer {
    background-color: var(--neutral-color-8);
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.BoxImagePreview .PreviewContainer img {
    max-width: 80%;
    object-fit: contain;
}

.BoxImagePreview .PreviewActionButton {
    margin-top: 5px;
    width: 100%;
}