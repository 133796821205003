
.MChoiceOptions .Section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.MChoiceOptions .AnswersInput {
    margin-top: 5px;
}

.MChoiceOptions .DeleteAnswerButton {
    padding-right: 0px;
}

.MChoiceOptions .AddAnswerButton {
    margin-top: 5px;
}

.MChoiceOptions .MChoiceGradeWeightInput {
    margin-bottom: 20px;
}