.CreateCourseContainer {
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 40px;
}

.CreateCourseBody {
    padding-top: 20px;
}

.CreateCourseInput {
    margin-bottom: 2rem;
}

.CreateCourseFooter {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 10px;
}

.CreateCourseFooter button {
    margin-left: 10px;
}

.CreateCourseContainer .CreateCourseErrors {
    margin-bottom: 20px;
}