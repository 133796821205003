.NavBarContainer {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: var(--workspace-primary-color-1)!important;
  backdrop-filter: blur(30px);
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 100;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(15px);
}

.NavBarCreateButton {
  background: none;
  color: var(--workspace-primary-color-1);
  border: none;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-right: 10px;
  padding-left: 10px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  background-color: var(--workspace-secondary-color-1);
  border-radius: 50px;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: normal;
  text-decoration: none;
  margin-right: 10px;
}

.NavBarRegularButton {
  background: none;
  color: var(--neutral-color-10);
  border: none;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-right: 10px;
  padding-left: 10px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  background-color: transparent;
  border-radius: 50px;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: normal;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.NavBarRegularButton:hover {
  color: var(--workspace-secondary-color-1)
}

.NavBarAvatarButton {
  background: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  background-color: transparent;
  margin-left: 5px;
}

.NavBarButtonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.NavBarLogo {
  margin-top: 6px;
}
