.ChallengeBranchAiHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 10px;
}

.ChallengeBranchAiHeader p {
    font-family: var(--font-family);
    font-size: 20px;
    font-weight: bold;
    color: var(--neutral-color-1);
    margin-bottom: 0px;
    margin-left: 5px;
}

.ChallengeBranchAiHeader span {
    font-size: 22px;
}

.ChallengeBranchAiHeader img {
    margin-left: 5px;
}

.GeneratedAnswerContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 17px;
}

.GeneratedAnswerContainer .GeneratedImageContainer {
    position: relative;
    width: 15rem;
    border-radius: 7px;
    overflow: hidden;
}

.GeneratedAnswerContainer .GeneratedImageContainer canvas {
    position: absolute;
    left: 0;
    opacity: 0.4;
    max-width: 100%;
}

.GeneratedAnswerContainer .GeneratedImageContainer img {
    max-width: 100%;
}

.GeneratedAnswerContainer .GeneratedQuestionContainer {
    max-width: 20rem;
}

.GeneratedAnswerContainer .GeneratedQuestionContainer p {
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: normal;
    color: var(--neutral-color-1);
    margin-bottom: 0px;
    margin-left: 10px;
}

.GeneratedAnswerContainer .Select {
    padding: 2px;
    border: 2px solid var(--neutral-color-7);
    outline: none;
    background: none;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    margin-right: 15px;
    
}

.GeneratedAnswerContainer .Selected {
    background-color: var(--success-color-1);
    width: 12px;
    height: 12px;
    border-radius: 11px;
    display: flex;
}

.ChallengeBranchAIContent .Result {
    font-family: var(--font-family);
    font-size: 20px;
    font-weight: bold;
    color: var(--neutral-color-1);
    margin-bottom: 20px;
    text-align: center;
}

.ChallengeBranchAIContent .Footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.ChallengeBranchAIContent .Footer .Button {
    margin-top: 10px;
}

