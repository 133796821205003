body {
  margin: 0;
  font-family: 'Walsheim';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.tooltip[id="d-t"] > div.tooltip-inner {
  background-color: white !important;
  color: black !important;
  font-family: 'Walsheim';
  color:#3a3a3a;
  font-size: 14px;
  line-height: normal;
  padding: 10px;
}

.tooltip[id="d-t"] > div.tooltip-arrow::before {
  border-bottom-color: white !important;
  color: white !important;
}

.bs-tooltip-bottom[id="d-t"] {
  filter: drop-shadow(0px 1px 5px #D2D2D2);
}

.tooltip[id="g-t"] > div.tooltip-inner {
  background-color: #1D7777 !important;
  color: white !important;
  font-family: 'Walsheim';
  color:#1D7777;
  font-size: 14px;
  line-height: normal;
  padding: 10px;
}

.tooltip[id="g-t"] > div.tooltip-arrow::before {
  border-bottom-color: #1D7777 !important;
  color: white !important;
}

.bs-tooltip-bottom[id="g-t"] {
  filter: drop-shadow(0px 1px 5px #D2D2D2);
}

.accordion-button {
  background-color: var(--workspace-primary-color-1) !important;
  color: white !important;
  font-family: 'Walsheim';
  font-size: 14px;
  font-weight: normal;
  color: white;
}

.accordion-button::after {
  color: white !important;
  stroke: white !important;
  fill: white !important;
  background-image: url('./assets/images/accordion_ic.svg') !important;
}

.MandatoryIndicator {
  padding-left: 3px !important;
  color: #FF0000 !important;
  padding-left: 3px !important;
  display: inline-block;
  margin-bottom: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Walsheim';
  src: local('GT_Walshheim'), url(./assets/fonts/GT-Walsheim-Pro-Black.ttf) format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'Walsheim';
  src: local('GT_Walshheim'), url(./assets/fonts/GT-Walsheim-Pro-Bold.ttf) format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Walsheim';
  src: local('GT_Walshheim'), url(./assets/fonts/GT-Walsheim-Pro-Medium.ttf) format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Walsheim';
  src: local('GT_Walshheim'), url(./assets/fonts/GT-Walsheim-Pro-Regular.ttf) format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'tapybl';
  src:  url('./assets/fonts/tapybl.eot?wc84qh');
  src:  url('./assets/fonts/tapybl.eot?wc84qh#iefix') format('embedded-opentype'),
    url('./assets/fonts/tapybl.ttf?wc84qh') format('truetype'),
    url('./assets/fonts/tapybl.woff?wc84qh') format('woff'),
    url('./assets/fonts/tapybl.svg?wc84qh#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'tapybl' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-checkbox-filled .path1:before {
  content: "\e915";
  color: rgb(0, 0, 0);
}
.icon-checkbox-filled .path2:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(34, 206, 41);
}
.icon-radiobutton-filled .path1:before {
  content: "\e935";
  color: rgb(0, 0, 0);
}
.icon-radiobutton-filled .path2:before {
  content: "\e936";
  margin-left: -1em;
  color: rgb(34, 206, 41);
}
.icon-type-folder_filled:before {
  content: "\e92b";
}
.icon-type-folder_empty:before {
  content: "\e92d";
}
.icon-type-bolder_x:before {
  content: "\e939";
}
.icon-type50:before {
  content: "\e900";
}
.icon-typeAdd-learner:before {
  content: "\e901";
}
.icon-typeArrow-down:before {
  content: "\e902";
}
.icon-typeAssign-Instructor:before {
  content: "\e903";
}
.icon-typeChallenge-Branch:before {
  content: "\e904";
}
.icon-typeCheck:before {
  content: "\e905";
}
.icon-typeCheckbutton---filled:before {
  content: "\e906";
}
.icon-typeChoice-Branch:before {
  content: "\e907";
}
.icon-typeChvron-Down:before {
  content: "\e908";
}
.icon-typeClock:before {
  content: "\e909";
}
.icon-typeClose:before {
  content: "\e90a";
}
.icon-typeCourse:before {
  content: "\e90b";
}
.icon-typeCourses-templates:before {
  content: "\e90c";
}
.icon-typeDifficulty:before {
  content: "\e90d";
}
.icon-typeDisabled-Eye:before {
  content: "\e90e";
}
.icon-typeDot:before {
  content: "\e90f";
}
.icon-typeEdit-with-square:before {
  content: "\e910";
}
.icon-typeEdit-without-square:before {
  content: "\e911";
}
.icon-typeEmpty-Checkbox:before {
  content: "\e912";
}
.icon-typeExit-Fullscreen:before {
  content: "\e913";
}
.icon-typeEye:before {
  content: "\e914";
}
.icon-typeFilter:before {
  content: "\e917";
}
.icon-typeFlag:before {
  content: "\e918";
}
.icon-typeFullscreen:before {
  content: "\e919";
}
.icon-typeGo-Back:before {
  content: "\e91a";
}
.icon-typeGradable-0:before {
  content: "\e91b";
}
.icon-typeGradable-1:before {
  content: "\e91c";
}
.icon-typeGradable-2:before {
  content: "\e91d";
}
.icon-typeHeart:before {
  content: "\e91e";
}
.icon-typeHelp:before {
  content: "\e91f";
}
.icon-typeHome:before {
  content: "\e920";
}
.icon-typeImage:before {
  content: "\e921";
}
.icon-typeInformation:before {
  content: "\e922";
}
.icon-typeLessson:before {
  content: "\e923";
}
.icon-typeLike:before {
  content: "\e924";
}
.icon-typeLog-out:before {
  content: "\e925";
}
.icon-typeLoop:before {
  content: "\e926";
}
.icon-typeMail:before {
  content: "\e927";
}
.icon-typeMessage:before {
  content: "\e928";
}
.icon-typeNotification---Empty:before {
  content: "\e929";
}
.icon-typeNotification---Filled:before {
  content: "\e92a";
}
.icon-typePage:before {
  content: "\e92c";
}
.icon-typePlay-second-version:before {
  content: "\e92e";
}
.icon-typePlay:before {
  content: "\e92f";
}
.icon-typePlus-icon---Circle:before {
  content: "\e930";
}
.icon-typePlus-icon:before {
  content: "\e931";
}
.icon-typeProfile:before {
  content: "\e932";
}
.icon-typeQuestion:before {
  content: "\e933";
}
.icon-typeRadiobutton-empty:before {
  content: "\e934";
}
.icon-typeReload:before {
  content: "\e937";
}
.icon-typeSave:before {
  content: "\e938";
}
.icon-typeSettings:before {
  content: "\e93a";
}
.icon-typeShare:before {
  content: "\e93c";
}
.icon-typeShuffle:before {
  content: "\e93d";
}
.icon-typeSingle-choice:before {
  content: "\e93e";
}
.icon-typeSound:before {
  content: "\e93f";
}
.icon-typeSquare:before {
  content: "\e940";
}
.icon-typeText:before {
  content: "\e941";
}
.icon-typeThree-dots:before {
  content: "\e942";
}
.icon-typeUsers:before {
  content: "\e943";
}
.icon-typeVideo:before {
  content: "\e945";
}
.icon-typeWarning:before {
  content: "\e946";
}
.icon-typeMultiple-choice:before {
  content: "\e929b";
}
.icon-typeOrganization:before {
  content: "\e92cc";
}
.icon-typeSearch:before {
  content: "\e934d";
}
.icon-typeRuler:before {
  content: "\e938a";
}
.icon-typeTrash:before {
  content: "\e943d";
}




:root {

  /* Shadows */
  --shadow-level-1: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.24);
  --shadow-level-2: 0px 3px 6px rgba(0, 0, 0, 0.16), 0px 3px 6px rgba(0, 0, 0, 0.23);
  --shadow-level-3: 0px 10px 20px rgba(0, 0, 0, 0.19), 0px 6px 6px rgba(0, 0, 0, 0.23);
  --shadow-level-4: 0px 14px 28px rgba(0, 0, 0, 0.25), 0px 10px 10px rgba(0, 0, 0, 0.22);
  --shadow-level-5: 0px 19px 38px rgba(0, 0, 0, 0.30), 0px 15px 12px rgba(0, 0, 0, 0.22);

  /* Branded colors */
    /* ---Primary */
    --primary-color-1: #011E1E;
    --primary-color-2: #03403E;
    --primary-color-3: #056260;
    --primary-color-4: #078482;
    --primary-color-5: #09A6A4;
    --primary-color-6: #0BC8C6;
    --primary-color-7: #1BD4D3;
    --primary-color-8: #55ECEB;
    --primary-color-9: #9EF4F4;
    /* ---Secondary */
    --secondary-color-1: #B8EF36;
    --secondary-color-2: #C0F152;
    --secondary-color-3: #C8F56D;
    --secondary-color-4: #D0F887;
    --secondary-color-5: #D8FBA2;
    --secondary-color-6: #E0FEBD;
    --secondary-color-7: #E8FFD7;
    --secondary-color-8: #F0FFE4;
    --secondary-color-9: #F8FFF1;
    --secondary-color-10: #FFFFFD;

  /* Action colors */
    /* ---Success */
    --success-color-1: #22CE29;
    --success-color-2: #3AD148;
    --success-color-3: #52D466;
    --success-color-4: #6BDB85;
    --success-color-5: #83E3A3;
    --success-color-6: #9BEBC1;
    --success-color-7: #B4F4E0;
    --success-color-8: #CCFCE0;
    --success-color-9: #E4FFE0;
    --success-color-10: #FDFFE0;
    /* ---Warning */
    --warning-color-1: #F7B200;
    --warning-color-2: #F7C51A;
    --warning-color-3: #F7D834;
    --warning-color-4: #F7EA4E;
    --warning-color-5: #F7FC68;
    --warning-color-6: #F8FF80;
    --warning-color-7: #FAFF99;
    --warning-color-8: #FCFFAE;
    --warning-color-9: #FEFFC4;
    --warning-color-10: #FFFFDA;
    /* ---Error */
    --error-color-1: #FF0000;
    --error-color-2: #FF1A1A;
    --error-color-3: #FF3434;
    --error-color-4: #FF4E4E;
    --error-color-5: #FF6868;
    --error-color-6: #FF8282;
    --error-color-7: #FF9C9C;
    --error-color-8: #FFB6B6;
    --error-color-9: #FFD0D0;
    --error-color-10: #FFEAEA;

  /* For typography or anything else */
  --neutral-color-1: #3A3A3A;
  --neutral-color-2: #535353;
  --neutral-color-3: #6D6D6D;
  --neutral-color-4: #868686;
  --neutral-color-5: #A0A0A0;
  --neutral-color-6: #BABABA;
  --neutral-color-7: #D4D4D4;
  --neutral-color-8: #EEEEEE;
  --neutral-color-9: #F4F4F4;
  --neutral-color-10: #FFFFFF;
  /* For typography or anything else */
  --workspace-primary-color-1:var(--primary-color-1);
  --workspace-primary-color-2:var(--primary-color-2);
  --workspace-primary-color-3:var(--primary-color-3);
  --workspace-primary-color-4:var(--primary-color-4);
  --workspace-primary-color-5:var(--primary-color-5);
  --workspace-primary-color-6:var(--primary-color-6);
  --workspace-primary-color-7:var(--primary-color-7);
  --workspace-primary-color-8:var(--primary-color-8);
  --workspace-primary-color-9:var(--primary-color-9);
  --workspace-primary-color-10:var(--primary-color-10);
  --workspace-secondary-color-1:var(--secondary-color-1);
  --workspace-secondary-color-2:var(--secondary-color-2);
  --workspace-secondary-color-3:var(--secondary-color-3);
  --workspace-secondary-color-4:var(--secondary-color-4);
  --workspace-secondary-color-5:var(--secondary-color-5);
  --workspace-secondary-color-6:var(--secondary-color-6);
  --workspace-secondary-color-7:var(--secondary-color-7);
  --workspace-secondary-color-8:var(--secondary-color-8);
  --workspace-secondary-color-9:var(--secondary-color-9);
  --workspace-secondary-color-10:var(--secondary-color-10);

  /* Typography */
    /* ---Headings */
    --font-family: 'Walsheim', sans-serif;
    --font-size-h1: 32px;
    --font-size-h2: 24px;
    --font-size-h3: 20px;
    --font-size-h4: 18px;
    --font-size-h5: 16px;
    --font-size-h6: 14px;
    /* ---Paragraphs */
    --font-size-paragraph: 16px;
    --line-height-paragraph: 24px;
    /* ---Various texts */
    --font-size-1: 0.7rem;
    --line-height-1: 1.05rem;
  
    --font-size-2: 0.8rem;
    --line-height-2: 1.2rem;
  
    --font-size-3: 0.9rem;
    --line-height-3: 1.35rem;
  
    --font-size-4: 1rem;
    --line-height-4: 1.5rem;
  
    --font-size-5: 1.1rem;
    --line-height-5: 1.65rem;
  
    --font-size-6: 1.2rem;
    --line-height-6: 1.8rem;
}

