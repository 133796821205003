
.VideoPlayerControls {
    position: absolute;
    bottom: 15px;
    left: 15px;
    right: 15px;
    background-color: #00000066;
    padding-top: 12px;
    padding-bottom: 12px;
    backdrop-filter: blur(15px);
    border-radius: 80px;
    padding-left: 22px;
    padding-right: 22px;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 2;
}

.VideoPlayerControlsSlim {
    padding: 8px 12px;
}

.VideoPlayerControls .Logo {
    height: 18px;
    margin-right: 12px;
}

.VideoPlayerControls .PlayButton {
    height: 18px;
    width: 18px;
    background: none;
	border: none;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
}

.VideoPlayerControls .Icon {
    width: 13px;
    height: 13px;
}

.VideoPlayerControls .TimeText {
    font-family: var(--font-family);
    font-size: 11px;
    font-weight: bold;
    color: var(--neutral-color-10);
    margin-bottom: 0;
    line-height: 11px;
}

.VideoPlayerControls .SeekLineContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    height: 12px;
    margin-left: 10px;
    margin-right: 10px;
}

.VideoPlayerControls .SeekLineOuter {
    background-color: #3D3D3D;
    height: 6px;
    border-radius: 6px;
    width: 100%;
    cursor: pointer;
}

.VideoPlayerControls .SeekLineInner {
    background-color: var(--workspace-secondary-color-1);
    height: 6px;
    border-radius: 6px;
    width: 0px;
}

.VideoPlayerControlsHidden {
    opacity: 0;
    transition: all 250ms linear 1s;
}