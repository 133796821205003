.ChallengeBranchAIOverlay {
    padding: 20px;
    height: 100%;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 20px;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.ChallengeBranchAIOverlayBackdrop {
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(10px);
}

.ChallengeBranchAIOverlay .ShowImageButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 5%;
}

.ChallengeBranchAIOverlay .MaskContainer canvas {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
}

.ChallengeBranchAIOverlay .MaskContainer .ShowQuestionButton {
    z-index: 2;
    position: relative;
}

.ChallengeBranchAIOverlay .Question {
    font-family: var(--font-family);
    font-size: 32px;
    font-weight: normal;
    color: var(--neutral-color-10);
    margin-bottom: 25px;
    text-align: center;
    margin-top: 5%;
}