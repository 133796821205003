.CheckboxContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    
}

.CheckboxText {
    margin-bottom: 0;
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: bold;
    color: #9E9E9E;
    cursor: pointer;
    margin-left: 5px;
}

.CheckboxOuterBox {
    width: 14px;
    height: 14px;
    border: 2px solid #9E9E9E;
    border-radius: 2px;
    margin-right: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.CheckboxOuterCircle {
    width: 14px;
    height: 14px;
    border: 2px solid #9E9E9E;
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.CheckboxChecked {
    width: 8px;
    height: 8px;
    background-color: #22CE29;
}

.CheckboxCircleChecked {
    width: 8px;
    height: 8px;
    background-color: #22CE29;
    border-radius: 8px;
}

.CheckboxContainer .Slim {
    font-weight: normal;
}