.UserMenuPopUpContainer {
  background-color: var(--neutral-color-10);
  border: 0 solid #f5f5f5e9;
  border-radius: 5px;
  position: absolute;
  top: 65px;
  min-width: 220px;
  height: auto;
  width: auto;
  font-family: var(--font-family);
  color:  var(--neutral-color-1);
  right: 0px;
  line-height: normal;
  filter: drop-shadow(0px 1px 5px rgba(58, 58, 58, 0.2));
  max-width: 20%;
}

.UserMenuPopUpContainer ul,
.UserMenuPopUpContainer p {
  list-style: none;
  padding: 0;
  margin: 0;
  font-weight: 500;
  padding-bottom:1rem;
}

.UserMenuPopUpContainer ul li:first-child {
  border-top: 1px solid #e4e3e3;
}

.UserMenuPopUpContainer ul li {
  display: flex;
  align-items: center;
}

.UserMenuPopUpContainer ul a:hover {
  background-color: var(--workspace-primary-color-3);
}

.UserMenuPopUpContainer ul a:hover,.UserMenuPopUpContainer ul a:hover span{
    color: var(--neutral-color-10);
}


.UserMenuPopUpContainer ul li:last-child {
  border-bottom: 0;
}


.UserMenuPopUpContainer p {
  font-size: 1.2rem;
  width: auto;
  line-height: normal;
  padding: 0px 15px;
  height: auto;
  color: var(--neutral-color-1);
  overflow: hidden;
  text-overflow: ellipsis;
}

.UserMenuPopUpContainer img {
  color: var(--neutral-color-1);
  border: 1.5px;
  height: auto;
}

.UserMenuPopUpContainer a {
  font-weight: 500;
  font-size: 1rem;
  text-decoration: none;
  color: var(--neutral-color-1);
  padding: 0.5rem;
  width: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
}

.UserMenuPopUpContainer a span{
  margin-right: 0.5rem;
}


.UserMenuPopUpContainer .ProfileSubtitle {
  font-family: var(--font-family);
  color: var(--neutral-color-1);
  font-size: 0.9rem;
  line-height: normal;
  font-style: italic;
  padding-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.UserMenuPopUpContainer .popupArrowDetail {
  width: 50px;
  position: absolute;
  height: 24px;
  overflow: hidden;
  right: 9px;
  top: -16px;
}

.UserMenuPopUpContainer .popupArrowDetail:before {
  content: '';
  width: 50px;
  position: absolute;
  height: 50px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 12px;
  z-index: 10;
  background:  var(--neutral-color-10);
  box-shadow: none;
  border: 0;
  border-radius: 5px;
}

.UserMenuPopUpContainer .ProfileButton {
  font-size: 1rem;
  width: auto;
  line-height: normal;
  padding: 10px 15px;
  height: auto;
  color: var(--neutral-color-1);
}
