.GenerateAIVideosContainer {
  max-width: 70vw;
}

.GenerateAIVideosContainer .CheckboxContainer{    
    justify-content: space-between;
    -webkit-appearance: none;
    display: flex;
    flex-direction: column;    
    padding-top: 5px;
    padding-bottom: 5px;
    gap: 20px;
    align-items: baseline;
    padding-left: 0px;
    padding-right: 0px;

}

.GenerateAIVideosContainer .CheckboxContainer .Slider{
    font-family: var(--font-family);
    font-size: 12px;
    color: #555555;
    font-weight: bold;    
    /* width:65%; */
    /* padding-bottom: 20px; */
}

.GenerateAIVideosContainer .DropdownContainers{
  /* width: 65%; */
  padding-top: 10px;
  padding-bottom: 10px;
}

.GenerateAIVideosContainer .CheckboxContainer .Slider .form-text{
  font-family: var(--font-family);
  font-size: 10px;
  font-weight: normal;
  color: var(--neutral-color-5);
  
}

.GenerateAIVideosContainer .CheckboxContainer .Slider .form-control{
  font-family: var(--font-family);
  font-size: var(--font-size-1);
  color: #555555;
  font-weight: bold;    
  width:100%;
  text-align-last: center;
}


.GenerateAIVideosContainer .CheckboxContainer .range-slider__wrap{
    width: 50%;
}

.GenerateAIVideosContainer .LanguageContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;  
  padding-bottom: 10px;
}

.GenerateAIVideosContainer .StepHeading{
  font-family: var(--font-family);
  font-size: var(--font-size-1);
  color: #555555;
  font-weight: bold;
  margin-bottom: 10px;
}

.GenerateAIVideosContainer .StepSubHeading{
  font-family: var(--font-family);
  font-size: 10px;
  font-weight: normal;
  color: var(--neutral-color-5);
}


.GenerateAIVideosContainer .Dropdown{
  display: flex;
  flex-direction: column;
  flex: 1;  
}

.GenerateAIVideosContainer .range-slider__tooltip {
  display: none;
}

.GenerateAIVideosContainer .CheckboxContainer .range-slider__wrap .range-slider, 
.GenerateAIVideosContainer .CheckboxContainer .range-slider__wrap .range-slider--primary{
    width: 100%;
    -webkit-appearance: none;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0;
    line-height: 1.5;
    font: 1rem / 1 arial, sans-serif;
    color: #ced4da;
    background-clip: padding-box;
    background: transparent;
    border: none;
    outline: none;
    box-shadow: none;
    position: relative;
}


.GenerateAIVideosContainer .Header h1{
    font-family: var(--font-family);
    font-size: 20px;    
    color: var(--neutral-color-1);
    margin-bottom: 20px;
}

.GenerateAIVideosContainer .Footer{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 20px;
    margin-top: 20px;
}

input[type='range'] {
    -webkit-appearance: none;
    width: 100%;
  }
  
  input[type='range']::-webkit-slider-runnable-track {
    height: 10px;
    background: #ddd;
    border: none;
    border-radius: 3px;
  }
  
  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: var(--workspace-secondary-color-1); /* Change this to your desired color */
    margin-top: -3px;
  }
  
  
  input[type='range']:focus {
    outline: none;
  }
  
  input[type='range']:focus::-webkit-slider-runnable-track {
    background: #ccc;
  }
  

  .ModalContent .LoadingContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: var(--font-size-1);
  }