.EmptyStateContainer {
    width: 100%;
    background-color: var(--neutral-color-9);
    border-radius: 5px;
    padding: 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.EmptyStateContainer .Title {
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: normal;
    color: var(--neutral-color-5);
    margin-top: 5px;
    margin-bottom: 0;
}

.EmptyStateContainer .Subtitle {
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: normal;
    color: var(--neutral-color-5);
    margin-bottom: 10px;
}