.NotificationsMenuPopUpContainer {
    background-color: var(--neutral-color-10);
    border: 0 solid #f5f5f5e9;
    border-radius: 5px;
    position: absolute;
    top: 65px;
    min-width: 220px;
    height: auto;
    width: auto;
    font-family: var(--font-family);
    color:var(--neutral-color-1);
    right: 25px;
    filter: drop-shadow(0px 1px 5px rgba(58, 58, 58, 0.20));
}

.NotificationsMenuPopUpContainer ul{
    max-height: 320px;
    overflow: hidden;
    overflow-y: auto;
  }

.NotificationsMenuPopUpContainer ul,
.NotificationsMenuPopUpContainer p {
    list-style: none;
    padding: 0;
    margin: 0;
    font-weight: 500;
}

.NotificationsMenuPopUpContainer ul li:first-child {
    border-top: 1px solid #F0F0F0;
}

.NotificationsMenuPopUpContainer ul li {
    padding: 5px 15px;
    border-bottom: 1px solid #F0F0F0;
}

.NotificationsMenuPopUpContainer ul li:last-child {
    border-bottom: 0;
}

.NotificationsMenuPopUpContainer p {
    font-size: 0.9rem;
    width: auto;
    line-height: normal;
    padding: 5px 25px;
    height: auto;
    color:var(--neutral-color-1);
    font-weight: 400;
    line-height: normal;
}

.NotificationsMenuPopUpContainer .HeadingText {
    font-family: var(--font-family);
    font-size: 1rem;
    width: auto;
    padding: 10px;
    display: block;
    height: auto;
    color:#000;
    font-weight: normal;
    line-height: normal;
}


.NotificationsMenuPopUpContainer img {
    color:var(--neutral-color-1);
    border:1.5px;
    height: auto;
}

.NotificationsMenuPopUpContainer p.SubtitleText {
    font-size: 0.8rem;
    padding:5px 25px;
    color: #858585;
    font-weight: 400;
    line-height: normal;
    width: auto;
}

.NotificationsMenuPopUpContainer li{
    display: flex;
    flex-direction: row;
    align-items: center;
    background: rgba(29, 119, 119, 0.05);
}

.NotificationsMenuPopUpContainer .TextContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 20vw;
}

.NotificationsMenuPopUpContainer .ButtonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 25px;
}

.NotificationsMenuPopUpContainer button {
    font-family: var(--font-family);
    font-weight:500;
    font-size: 0.8rem;
    text-decoration: none;
    color: var(--neutral-color-1);
    display: inline-flex;
    padding: 3px 8px;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    border: 0.5px solid #CBD1D8;
    background: #FFF;
    margin:auto 5px;
    min-width: 5px;
}

.NotificationsMenuPopUpContainer button.ProfilePicture {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    border: 0.5px solid #CBD1D8;
    
}

.NotificationsMenuPopUpContainer button.AcceptButton {
    font-family: var(--font-family);
    font-weight:500;
    font-size: 0.8rem;
    text-decoration: none;
    color: var(--neutral-color-10);
    padding:3px 8px;
    background-color: var(--workspace-primary-color-3);
    border-radius: 2px;
    border: 0.5px solid #CBD1D8;
    min-width: 5px;
}

.NotificationsMenuPopUpContainer button.ViewAllNotificationsButton {
    font-family: var(--font-family);
    font-weight:500;
    font-size: 0.8rem;
    text-decoration: none;
    color: var(--neutral-color-10);
    border: 0.5px solid #CBD1D8;
    min-width: 5px;
    border-radius: 3px;
    background: #005250;
    display: inline-flex;
    padding: 5px;
    justify-content: center;
    align-items: center;
    margin: 5px 10px 0px auto;
    display: block;
}

.NotificationsMenuPopUpContainer .popupArrowDetail {
    width: 50px;
    position: absolute;
    height: 24px;
    overflow: hidden;
    right: 20px;
    top: -16px;
}

.NotificationsMenuPopUpContainer .popupArrowDetail:before {
    content: "";
    width: 50px;
    position: absolute;
    height: 50px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 12px;
    z-index: 10;
    background: var(--neutral-color-10);
    box-shadow: none;
    border: 0;
    border-radius: 5px;
}

.NotificationsMenuPopUpContainer .EmptyState {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: rgba(29, 119, 119, 0.05);
    padding: 10px;
    border-bottom: 1px solid #D8D8D8;
    border-top: 1px solid #D8D8D8;
}

.NotificationsMenuPopUpContainer .EmptyState h3 {
    font-family: var(--font-family);
    font-weight: lighter;
    font-size: 14px;
    color: var(--neutral-color-1);
    margin-bottom: 0;
    font-style: italic;
    }

.NotificationsCounter {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: rgb(237, 8, 8);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.NotificationsCounter span {
    font-family: var(--font-family);
    font-weight: normal;
    font-size: 9px!important;
    color: var(--neutral-color-10);
    display: block;
}

.NavBarNotificationsButton{
    width: 32px;
    height: 32px;
    background: none;
    padding: 6px;
    align-items: center;
    justify-content: center;
    display: flex;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    fill:var(--neutral-color-10);
    border: none;  
    position: relative;
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -ms-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
}

.NavBarNotificationsButton span{
    color:var(--neutral-color-10);
    font-size: 1.2rem;
}

.NavBarNotificationsButton:hover{
    -moz-transform: rotate(10deg);
    -webkit-transform: rotate(10deg);
    -o-transform: rotate(10deg);
    -ms-transform: rotate(10deg);
    transform: rotate(10deg);
}

.NavBarNotificationsButton:hover span{
    color:var(--workspace-secondary-color-1);
}

@media only screen and (max-width: 767px) {
    .NotificationsMenuPopUpContainer .TextContainer{
        max-width: none;
    }
    .NotificationsMenuPopUpContainer{
        max-width: 90%;
    }
}
