.ChallengeBranchOverlay {
  padding: 20px;
  height: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.ChallengeBranchOverlay .Content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: calc(100vh / 50);
  min-height: 95%;
}

/* .ChallengeBranchOverlay .Content:after {
  content: "▼ ▼ ▼";
  position: fixed;
  bottom: 0;
  padding: 5px;
  font-size: 12px;
  z-index: 1;
  color: #fff;
  width: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  text-align: center;
} */

.ChallengeBranchOverlay::-webkit-scrollbar {
  display: none;
}

.ChallengeBranchOverlay p {
  font-family: var(--font-family);
  font-size: calc(100vh / 40);
  font-weight: normal;
  color: var(--neutral-color-10);
  margin-bottom: 25px;
  text-align: center;
}

.ChallengeBranchOverlay .ImageOption {
  object-fit: cover;
  border-radius: 10px;
  cursor: pointer;
  border: 2px solid transparent;
  max-width: 20%;
  margin-bottom: 0.5rem;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.ChallengeBranchOverlay .ImageOption:hover {
  border: 2px solid var(--workspace-secondary-color-3);
  transform: scale(0.96) translateY(-2px);
  -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}

.ChallengeBranchOverlay .TextChoice {
  border-radius: 10px;
  cursor: pointer;
  background-color: var(--neutral-color-10);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 2px solid transparent;
  margin-bottom: 0.5rem;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  min-width: 100px;
  min-height: 100px;
}

.ChallengeBranchOverlay .TextChoice:hover {
  transform: scale(0.98);
  -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  background-color: var(--workspace-primary-color-3);
}

.ChallengeBranchOverlay .TextChoice:hover p {
  color: var(--neutral-color-10);
}

.ChallengeBranchOverlay .TextChoice p {
  font-family: var(--font-family);
  font-size: calc(100vh / 40);
  font-weight: normal;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: var(--neutral-color-1);
  margin-bottom: 0;
}

.ChallengeBranchOverlay .AnswersContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
}
