.SubscriptionSettingsContainer {
    padding-top: 20px;
}

.SubscriptionSettingsContainer .SubscriptionField {
    margin-top: 20px;
}

.SubscriptionSettingsContainer .UnsubscribeText {
    font-family: var(--font-family);
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    color: var(--error-color-3);
    margin-bottom: 8px;
    margin-top: 20px;
}

.SubscriptionSettingsContainer .PaymentMethodText {
    font-family: var(--font-family);
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    color: black;
    margin-bottom: 8px;
    margin-top: 20px;
}

.ActionsSectionContainer {
    margin-top: 30px;
}

.ActionsSectionContainer h3 {
    font-family: var(--font-family);
    font-size: var(--font-size-h3);
    color: black;
    padding-bottom: 15px;
    border-bottom: 1px solid var(--neutral-color-7);
    width: 100%;
    margin-bottom: 30px;
}

.InvoicesSectionContainer {
    margin-top: 30px;
}

.InvoicesSectionContainer h3 {
    font-family: var(--font-family);
    font-size: var(--font-size-h3);
    color: black;
}