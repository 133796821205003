.LearnerInvitationsContainer {
    margin-top: 20px;
}

.LearnerInvitationsContainer .Header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.LearnerInvitationsContainer .AddButton {
    margin-left: 10px;
}

.LearnerInvitationsContainer .SearchInputOuterContainer .Input {
    margin-right: 10px;
    margin-left: 6px;
}

.LearnerInvitationsContainer .NoLearners {
    font-family: var(--font-family);
    font-weight: bold;
    color: #adadad;
    font-size: 0.8rem;
    padding: 4rem 0;
}

.LearnerInvitationsContainer .Table {
    width: 95%;
    font-family: var(--font-family);
    font-size: 20px;
    font-weight: normal;
    color: var(--neutral-color-5);
    margin-top: 30px;
}

.LearnerInvitationsContainer .TableText {
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: normal;
    color: var(--neutral-color-1);
    margin-bottom: 0;
}

.LearnerInvitationsContainer .InvitedStatus {
    padding: 5px 20px 5px 20px;
    border-radius: 50px;
    border: 1px solid var(--neutral-color-2);
    background: #D3D5D426;
    color: var(--neutral-color-2);
    white-space: nowrap;
}