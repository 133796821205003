.AILoader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
}
  
.AILoader span {
    font-family: var(--font-family);
    font-size: 15px;
    font-weight: bold;
    color: var(--neutral-color-1);
    margin-top: 10px;
}


.loader-container {
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader-container img:first-child {
    position: absolute;
    width: 50px;
    z-index: 1;
}

.loader-container img:last-child {
    position: absolute;
    width: 170px;
}