.AddFreeformOverlay {
    display: flex;
    flex-direction: row;
}

.AddFreeformOverlay .PlayerContainer {
    width: 50vw;
    position: relative;
}

.AddFreeformOverlay .SectionHeader {
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: normal;
    color: var(--neutral-color-1);
    text-align: center;
}

.AddFreeformOverlay .OptionsContainer {
    width: 18vw;
}

.AddFreeformOverlay .OptionsContent {
    padding-left: 15px;
}

.AddFreeformFooter {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 20px;
    margin-left: 10vw;
    margin-right: 10vw;
}

.AddFreeformOverlay .OptionsInput {
    margin-bottom: 15px;
}

.AddFreeformOverlay .OptionsContent .VisibleCheck {
    margin-top: 15px;
}