.GradebookContainer {
    padding-top: 20px;
}

.GradebookContainer .NoLearners {
    font-family: var(--font-family);
    font-weight: bold;
    color: #adadad;
    font-size: 0.8rem;
    padding: 4rem 0;
    text-align: center;
}

.GradebookContainer .Header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 20px;
}

.GradebookContainer .Header .Filter {
    margin-right: 10px;
}

.GradebookTableContainer {
    max-height: 80vh;
    overflow: auto;
    padding-bottom: 20px;
}

.GradebookTable {
    position: relative;
    border-collapse: separate; /* Don't collapse */
    border-spacing: 0;
}

.GradebookTable .GradebookHeader {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
}

.GradebookTable .LearnerHeader {
    position: sticky;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 2;
    border-right: 2px solid var(--neutral-color-7);
}

.GradebookTable .FinalGradeHeader {
    position: sticky;
    top: 0;
    right: 0;
    background-color: white;
    z-index: 2;
    border-left: 2px solid var(--neutral-color-7);
}

.GradebookTable .SelectedRowCol {
    background-color: var(--neutral-color-6) !important;
}

.GradebookTable th {
    font-family: var(--font-family);
    font-size: var(--font-size-3);
    color: var(--neutral-color-5);
    font-weight: normal;
    padding: 20px 10px;
    text-align: center;
    cursor: pointer;
    border-bottom: 2px solid var(--neutral-color-7);
    max-width: 150px;
}

.GradebookTable td {
    font-family: var(--font-family);
    font-size: var(--font-size-3);
    color: var(--neutral-color-1);
    font-weight: normal;
    padding: 5px 10px;
    text-align: center;
    min-width: 150px;
    padding: 10px 20px;
}

.GradebookTable .OddRow {
    background-color: #F2F2F2;
}

.GradebookTable .EvenRow {
    background-color: white;
}

.GradebookTable .LearnerName {
    position: sticky;
    left: 0;
    font-weight: bold;
    width: 5vw;
    cursor: pointer;
    border-right: 2px solid var(--neutral-color-7);
    max-width: 150px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.GradebookTable .LearnerName span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 150px;
    display: block;
}

.GradebookTableContainer .PagingContainer {
    position: sticky;
    bottom: 0;
    left: 0;
    background-color: white;
}

.GradebookTable .FinalGrade {
    position: sticky;
    right: 0;
    font-weight: bold;
    border-left: 2px solid var(--neutral-color-7);
}

.GradebookTableContainer .LessonHeader p {
    margin-bottom: 0;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
}

.GradebookTable .GradeButton {
    border: none;
    background: none;
    outline: none;
}