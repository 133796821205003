.NotificationsUserProfile {
    margin-left: 20px;
}

.NotificationsUserProfile .EmptyState {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: rgba(29, 119, 119, 0.05);
    padding: 10px;
    border-bottom: 1px solid #D8D8D8;
    border-top: 1px solid #D8D8D8;
    list-style-type: none;
    height: 80%;
}

.UserProfileNotificationEmptyState {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    justify-content: center;
    width: 100%;
    min-height:50vh;
    align-items: center;
}

.UserProfileNotificationEmptyState h3 {
    list-style-type: none;
    font-family: var(--font-family);
    font-weight: normal;
    font-size: var(--font-size-2);
    color: var(--neutral-color-5);
    margin-bottom: 0;
}

.NotificationsSectionHeader {
    padding-left: 15px;
    font-family: var(--font-family);
    font-weight: normal;
    font-size: var(--font-size-h6);
    color: var(--neutral-color-4);
}