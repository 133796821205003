.CoursesListHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.CourseAvatarPlaceholder {
    background-color: var(--workspace-primary-color-3);
    width: 40px;
    min-width: 40px;
    height: 26px;
    border-radius: 5px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.CourseAvatarPlaceholder span {
    font-family: var(--font-family);
    font-size: 16px;
    color: var(--neutral-color-10);
    font-weight: normal;
}

.CourseFiltersContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.CourseFiltersContainer .Input {
    margin-right: 10px;
}

[data-column-id="course_category"].rdt_TableCell,
[data-column-id="course_type"].rdt_TableCell,
[data-column-id="course_enrolled"].rdt_TableCell,
[data-column-id="course_completed"].rdt_TableCell,
[data-column-id="course_avg"].rdt_TableCell,
[data-column-id="course_actions"].rdt_TableCell,
[data-column-id="course_visibility"].rdt_TableCell
{
    text-align: center;
    width: 100%;
    justify-content: center;
}

[data-column-id="course_category"].rdt_TableCol_Sortable,
[data-column-id="course_type"].rdt_TableCol_Sortable,
[data-column-id="course_enrolled"].rdt_TableCol_Sortable,
[data-column-id="course_completed"].rdt_TableCol_Sortable,
[data-column-id="course_avg"].rdt_TableCol_Sortable,
[data-column-id="course_actions"].rdt_TableCol_Sortable,
[data-column-id="course_visibility"].rdt_TableCol_Sortable
{
    text-align: center;
    width: 100%;
    justify-content: center;
}

[data-column-id="actions"].rdt_TableCol {
    text-align: center;
    width: 100%;
    justify-content: center;
    padding-left: 10px;
}


.ModelRow{
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: var(--font-family);
    color: var(--neutral-color-1);
    font-weight: normal;
}

.AddLearnerTable{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.AddLearnerTable .DataTableText {
    max-width:100%!important;
}

.ModelRow h5{
    padding: 5px 10px 0px 10px;
    font-family: var(--font-family);
    color: var(--neutral-color-1);
    font-weight: normal;
    font-size: 14px;
}

.ModelRow img{
border-radius: 50%;
    width: 40px;
    height: 40px;
    object-fit: cover;
    object-position: center;
    border: 2px solid var(--workspace-secondary-color-1);
}

[data-column-id="course_published"],
[data-column-id="course_public"]
 {
    justify-content: center;
  }