.VideoSourcesContainer {
  padding-bottom: 30px;
}

.VideoSourcesContainer .RecentFilesContainer {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  padding-bottom: 20px;
  padding: 1.5rem;
  background: var(--neutral-color-8);
  border-radius: 5px;
}

.VideoSourcesContainer .HeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.VideoSourcesContainer .SearchContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.VideoSourcesContainer .GenerateAiButtonContainer{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-left: 2px ;
  padding-right: 2px;
}

.VideoSourcesContainer .GenerateAiButtonContainer Button{
  min-height: 35px;
}

.VideoSourcesContainer .SearchContainer .Search {
  margin-right: 10px;
}

.VideoSourcesContainer .RecentFilesTitle {
  font-family: var(--font-family);
  font-size: var(--font-size-h5);
  font-weight: normal;
  color: var(--neutral-color-5);
  margin-top: 30px;
}

.VideoSourcesContainer .RecentFilesContainer .ItemContainer {
  margin-right: 20px;
  background: var(--neutral-color-10);
  padding: 0.5rem;
  border-radius: 5px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.VideoSourcesContainer
  .RecentFilesContainer
  .ItemContainer
  .PlaceholderContainer {
  position: relative;
  height: 80px;
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f3f3f3;
  border-radius: 5px;
}

.VideoSourcesContainer .RecentFilesContainer .ItemContainer .LogoPlaceholder {
  background-color: var(--workspace-primary-color-2);
}

.VideoSourcesContainer .RecentFilesContainer .ItemContainer .DurationContainer {
  position: absolute;
  right: 10px;
  bottom: 10px;
  background-color: #00000099;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 20px;
}

.VideoSourcesContainer .RecentFilesContainer .ItemContainer h6 {
  font-family: var(--font-family);
  font-size: 10px;
  font-weight: normal;
  color: var(--neutral-color-10);
  margin-bottom: 0;
}

.VideoSourcesContainer .RecentFilesContainer .ItemContainer img {
  max-width: 100%;
  object-fit: contain;
  max-height: 80px;
}

.VideoSourcesContainer .RecentFilesContainer .ItemContainer h5 {
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: normal;
  color: var(--neutral-color-1);
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 10px;
  white-space: nowrap;
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.VideoSourcesContainer
  .RecentFilesContainer
  .ItemContainer
  .FooterInfoContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.VideoSourcesContainer
  .RecentFilesContainer
  .ItemContainer
  .FooterInfoContainer
  .FooterInfoContainerType {
  display: flex;
  align-items: center;
}

.VideoSourcesContainer
  .RecentFilesContainer
  .ItemContainer
  .FooterInfoContainer
  img {
  border-radius: 0px;
  margin-right: 5px;
}

.VideoSourcesContainer
  .RecentFilesContainer
  .ItemContainer
  .FooterInfoContainer
  span {
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: normal;
  color: #979797;
  margin-right: 5px;
}

.VideoSourcesContainer .AllFilsTitle {
  font-family: var(--font-family);
  font-size: var(--font-size-h5);
  font-weight: normal;
  color: var(--neutral-color-5);
  margin-top: 30px;
}

.VideoSourcesContainer .TableDeleteButton {
  background: none;
  color: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  background-color: none;
}

.VideoSourcesContainer .TableNameContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.VideoSourcesContainer .TableNameContainer .TableNameContainerThumb {
  width: 50px;
  background: var(--neutral-color-7);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
}

.VideoSourcesContainer .TableNameContainer img {
  max-width: 48px;
  border-radius: 5px;
  max-height: 38px;
  padding-left: 5px;
  padding-right: 5px;
}

.VideoSourcesContainer .TableText {
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: normal;
  color: var(--neutral-color-1);
  margin-bottom: 0;
}

.VideoSourcesContainer .TableTextNotReady {
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: normal;
  color: var(--neutral-color-6);
  margin-bottom: 0;
}

.VideoSourcesContainer .rdt_TableHead {
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: normal;
  color: var(--neutral-color-5);
}

.VideoSourcesContainer .rdt_TableRow {
  border: none;
  border-bottom-width: 0px !important;
}

.VideoSourcesContainer .rdt_TableHeadRow {
  border-bottom-width: 0px !important;
  min-height: 0px !important;
  padding-bottom: 20px !important;
  padding-top: 10px;
}

.VideoSourcesContainer .DeleteDisabled {
  color: var(--neutral-color-5);
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  font-size: var(--font-size-4);
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.VideoSourcesContainer .Delete {
  color: var(--error-color-1);
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  font-size: var(--font-size-4);
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.VideoSourcesContainer .Preview {
  color: var(--workspace-primary-color-1);
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  font-size: var(--font-size-4);
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  margin-left: 10px;
}

.VideoSourcesContainer .Preview:hover {
  -moz-transform: rotate(10deg);
  -webkit-transform: rotate(10deg);
  -o-transform: rotate(10deg);
  -ms-transform: rotate(10deg);
  transform: rotate(10deg);
  opacity: 0.5;
  cursor: pointer;
}

.VideoSourcesContainer .Delete:hover {
  -moz-transform: rotate(10deg);
  -webkit-transform: rotate(10deg);
  -o-transform: rotate(10deg);
  -ms-transform: rotate(10deg);
  transform: rotate(10deg);
  opacity: 0.5;
  cursor: pointer;
}

.VideoSourcesContainer .MaxFileSize {
  font-family: var(--font-family);
  font-size: 11px;
  font-weight: normal;
  color: var(--neutral-color-5);
  text-align: center;
  position: absolute;
  bottom: -20px;
  white-space: nowrap;
}

.VideoSourcesContainer .UploadContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.VideoSourcesContainer [data-column-id='size'].rdt_TableCol_Sortable,
[data-column-id='actions'].rdt_TableCol_Sortable,
[data-column-id='created_date'].rdt_TableCol_Sortable,
[data-column-id='size'].rdt_TableCell,
[data-column-id='created_date'].rdt_TableCell,
[data-column-id='actions'].rdt_TableCell {
  /* text-align: center;
  width: 100%; */
  justify-content: center;
}

.VideoSourcesContainer [data-column-id='size'].rdt_TableCol_Sortable {
  padding-right: 10px;
}

.VideoSourcesContainer [data-column-id='created_date'].rdt_TableCol_Sortable {
  padding-right: 5px;
}

.VideoSourcesContainer .NoPlaceholderImage {
  height: 30px;
  background-color: var(--workspace-primary-color-2);
}