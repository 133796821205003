.FreeformOverlayCanvas {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.188);
    border-radius: 20px;
    z-index: 1;
}

.FreeformPlayContainer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
}

.FreeformPlayContainer img {
    width: 50px;
    background-color: var(--workspace-primary-color-2);
    padding: 6px;
    border-radius: 50px;
    cursor: pointer;
}

.FreeformPlayContainer img:hover {
    width: 55px;
}