.EnrollLearnersWizardContainer .Header {
    font-family: var(--font-family);
    font-size: 20px;
    font-weight: bold;
    color: var(--neutral-color-1);
    margin-bottom: 20px;
}

.EnrollLearnersWizardContainer .Footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 20px;
    margin-top: 20px;
}

.EnrollLearnersWizardContainer h1 {
    font-family: var(--font-family);
    font-size: 40px;
    font-weight: bold;
    color: var(--neutral-color-1);
}

.EnrollLearnersWizardContainer h3 {
    font-family: var(--font-family);
    font-size: 20px;
    font-weight: normal;
    color: var(--neutral-color-5);
}

.EnrollLearnersWizardContainer .DateSearchContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}


.EnrollLearnersWizardContainer .DateSearchContainer .SearchInput {
    width: 40%;
}

.EnrollLearnersWizardContainer .NoLearnersText {
    font-family: var(--font-family);
    font-size: var(--font-size-h5);
    color: var(--neutral-color-1);
    margin-top: 50px;
}

[data-column-id="CheckboxColumn"].rdt_TableCell {
    min-width: 10px;
    padding: 5px;
}

[data-column-id="NameColumn"].rdt_TableCol
{
    right: 10px;
    min-width: 10px;
    padding: 5px;
}
[data-column-id="NameColumn"].rdt_TableCell
 {
    min-width: 10px;
}

.LearnerModalColumn{
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font-family: var(--font-family);
    color: var(--neutral-color-1);
    font-weight: normal;
    min-width: none;
}

.LearnerModalColumn .Initials{
    font-family: var(--font-family);
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: normal;
    border-radius: 50%;
    width: 34px;
    height: 34px;
    object-fit: cover;
    object-position: center;
    border: 2px solid var(--workspace-secondary-color-1);
    display: flex;
    position: relative;
    color: var(--neutral-color-10);
    background-color: var(--workspace-primary-color-3);    
    margin-right: 8px;
}

.LearnerModalColumn h5{
    padding: 5px 10px 0px 10px;
    font-family: var(--font-family);
    color: var(--neutral-color-1);
    font-weight: normal;
    font-size: 14px;
}

.LearnerModalColumn img{
    border-radius: 50%;
    width: 40px;
    height: 40px;
    object-fit: cover;
    object-position: center;
    border: 2px solid var(--workspace-secondary-color-1);
}