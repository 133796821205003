
.AddVideoOverlaySourceStepContent .CropPlayerContainer {
    width: 50vw;
    position: relative;
}

.AddVideoOverlaySourceStepContent .LogoPLaceholder {
    object-fit: contain;
    background-color: var(--workspace-primary-color-2);
    padding-left: 3px;
    padding-right: 3px;
}

.CropPlayerContent {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.AddVideoOverlaySourceStepContent .CropHeader {
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: normal;
    color: var(--neutral-color-1);
    text-align: center;
}

.AddVideoOverlaySourceStepContent .TimePickersContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    width: 30%;
}

.AddVideoOverlaySourceStepContent .UseFullVideoContainer {
    margin-top: 10px;
}

.AddVideoOverlaySourceStepContent .UseFullVideoContainer button {
    background: none;
    outline: none;
    border: none;
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: normal;
    color: #9F9F9F;
}

.AddVideoOverlaySourceStepContent .Footer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 80%;
    margin-top: 20px;
}

.OverlaySettings {
    padding-left: 20px;
    padding-left: 20px;
}

.OverlayTimeInputsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
}

.OverlaySettings .RemovePointButton {
    margin-left: 10px;
}

.OverlaySettings  .OverlaysList {
    max-height: 70vh;
    overflow-y: auto;
    min-width: 262.5px;
}

.OverlaysList .OverlayHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.OverlaysList .OverlayHeader .OverlayWarning {
    margin-left: 5px;
}

.OverlaysList .ActionTimePayloadInput {
    align-items: flex-start;
    margin-top: 8px;
}

.OverlaySettings .AddOverlayButton {
    width: 100%;
    margin-bottom: 10px;
}

.OverlaySettings .DestinationInput {
    margin-top: 10px;
}

.OverlaySettings .Title {
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: normal;
    color: var(--neutral-color-1);
    text-align: center;
}

.OverlaySettings .VisibleCheck {
    margin-top: 10px;
}