.TooltipArrowDetail:before {
    content: "";
    width: 40px;
    position: absolute;
    height: 40px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 12px;
    z-index: 10;
    background: var(--neutral-color-10);
    box-shadow: none;
    border: 0;
    border-radius: 5px;
}

.TooltipArrowDetail {
    width: 40px;
    position: absolute;
    height: 24px;
    overflow: hidden;
    right: 20px;
    top: -16px;
}

.TooltipContainer {
    background-color: var(--neutral-color-10);
    border: 0 solid #f5f5f5e9;
    border-radius: 5px;
    position: absolute;
    width: auto;
    top: 140%;
    left: 5%;
    font-family: var(--font-family);
    color:var(--neutral-color-1);
    right: 0px;
    line-height: normal;
    padding: 10px;
}

.TooltipContainer p {
    font-family: var(--font-family);
    color:var(--neutral-color-1);
    font-size: 14px;
    line-height: normal;
    margin-bottom: 0;
}