.TimeInputContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.TimeInputContainer .LabelTop {
    color: var(--neutral-color-1);
    font-size: 14px;
}

.TimeInputContainer input {
    width: 4rem;
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: normal;
    color: var(--neutral-color-1);
    text-align: center;
    border: none;
    outline: none;
    background-color: var(--neutral-color-8);
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    border-radius: 5px;
}

.Invalid input {
    border: 1px solid red;
}

.TimeInputContainer input::placeholder {
    color: #3A3A3A59;
}

.TimeInputContainer p {
    font-family: var(--font-family);
    font-size: 12px;
    color: #B1B1B1;
    font-weight: normal;
    margin-bottom: 0;
}
