.CookiePromptContainer {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 30px;
    background-color: #011E1E;
    border-radius: 10px;
    padding: 12px;
}

.CookiePromptContainer .CloseButton {
    color: white;
    background: none;
    outline: none;
    border: none;
    font-size: 12px;
}

.CookiePromptContainer h3 {
    font-size: var(--font-size-h3);
    color: white;
    font-family: var(--font-family);
}

.CookiePromptContainer p {
    font-size: var(--font-size-4);
    color: white;
    font-family: var(--font-family);
    margin-bottom: 0;
}

.CookiePromptContainer .TextContainer {
    padding-right: 18px;
    padding-left: 18px;
    padding-bottom: 18px;
}