.HomeStatisticsContainer {
    background-color: var(--workspace-primary-color-1);
    padding-top: 16px;
    padding-bottom: 12px;
}

.HomeStatisticsContainer .StatisticsRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.HomeStatisticsContainer .LearnerOrgDetails {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.HomeStatisticsContainer .LearnerOrgDetails img {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    margin-right: 10px;
}

.HomeStatisticsContainer .LearnerOrgDetails h2 {
    font-family: var(--font-family);
    font-size: 18px;
    font-weight: bold;
    color: var(--neutral-color-10);
    margin-bottom: 0;
}

.HomeWelcomeText {
    font-family: var(--font-family);
    font-size: 24px;
    font-weight: bold;
    color: var(--neutral-color-10);
}

.HomeSectionTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
}

.HomeSectionTitle h3 {
    font-family: var(--font-family);
    font-size: 24px;
    font-weight: bold;
    color: var(--neutral-color-1);
    margin-bottom: 30px;
}

.HomeViewAllButton {
    background: none;
	color: var(--neutral-color-1);
	border: 1px solid #EAEAEA;
	padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 10px;
    padding-right: 10px;
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: normal;
	cursor: pointer;
    border-radius: 5px;
}

.HomeCoursesList {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    padding-bottom: 15px;
}


.HomeEmptyStateContainer {
    margin-top: 40px;
    display: flex;
    background-color: var(--neutral-color-9);
    flex-direction: column;
    align-items: center;
    padding-top: 15vh;
    padding-bottom: 15vh;
}

.HomeEmptyStateContainer .Title {
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: normal;
    color: var(--neutral-color-5);
    margin-top: 5px;
    margin-bottom: 0;
}

.HomeEmptyStateContainer .ButtonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 40px;
}

.HomeEmptyStateContainer .ButtonsContainer p {
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: normal;
    color: var(--neutral-color-5);
    margin-bottom: 10px;
    text-align: center;
}

.HomeEmptyStateContainer .ButtonsContainer .Button {
    margin-left: 25px;
    margin-right: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.HomeCoursesContainer{
    padding:2rem 0;
    font-family: var(--font-family);
}

.HomeCoursesContent{
    margin-top:2rem;
    margin-bottom:2rem;
}


.HomeCourseTitle{
    margin-bottom:1rem;
    font-family: var(--font-family);
  }