.OnboardingInputContainer {
  margin-top: 26px;
}

.OnboardingFormContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  height: 90%;
  padding: 1rem 1rem 2rem 1rem;
}

.OnboardingOption {
  margin-bottom: 5px;
}

.OnboardingSubmitButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
}

.OnboardingSubmitButtonContainer .Skip {
  border: none;
  outline: none;
  background: none;
  color: #898989;
  font-size: 14px;
  font-family: var(--font-family);
  font-weight: normal;
  text-decoration: underline;
}

@media only screen and (min-width: 1025px) {
  .OnboardingFormContainer {
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: column;
    height: 90%;
    padding: 54px;
  }
}
