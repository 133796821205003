.EditorSvgCanvas {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.188);
    border-radius: 20px;
}

.SvgText {
    font-family: var(--font-family);
    user-select: none;
}