.NavigationTabsContainer {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  padding: 10px 0 10px 10px;
  justify-content: center;
  width: 100%;
}

.NavigationTabsContainer a {
  background: none;
  outline: none;
  border: none;
  padding-bottom: 9px;
  padding-top: 9px;
  padding-left: 14px;
  padding-right: 14px;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: normal;
  color: var(--neutral-color-6);
  border-bottom: 2px solid var(--neutral-color-8);
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.NavigationTabsContainer a:hover {
  border-bottom: 2px solid var(--workspace-primary-color-3);
  color:var(--workspace-primary-color-3);
}

.NavigationTabsContainer .ActiveButton {
  color: var(--neutral-color-1);
  border-bottom: 2px solid var(--workspace-primary-color-3);
}

.NavigationTabsContainer .FillBorder {
  display: flex;
  flex: 1;
  border-bottom: 1px solid var(--neutral-color-8);
}

/* Big screens (1025px and above) */
@media only screen and (min-width: 1025px) {
  .NavigationTabsContainer {
    padding: 10px 0 10px 35px;
  }
}
