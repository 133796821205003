
.StartingOverlay {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    backdrop-filter: blur(10px);
}

.StartingOverlay .Title {
  font-family: var(--font-family);
  font-size: 2.2rem;
  font-weight: normal;
  color: var(--neutral-color-10);
}

.StartingOverlay img {
  margin-bottom: 40px;
}

.StartingOverlay .Description {
  font-family: var(--font-family);
  font-size: 1.2rem;
  font-weight: normal;
  color: var(--neutral-color-10);
  text-align: center;
}

.StartingOverlay .StartButton {
  margin-top: 30px;
}


@media only screen and (max-width: 767px) {
    .StartingOverlay .Title{
        font-size: 1.2rem;
    }
    .StartingOverlay .Description{
        font-size: 1rem;
        padding-left:0.3rem;
        padding-right:0.3rem;
    }
  }
  