.ForgotPasswordErrorContainer {
  width: 100%;
  margin: 0;
  margin-top: .5rem;
}

.ForgotPasswordFormContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  height: 90%;
  padding: 1rem 1rem 2rem 1rem;
}

.ForgotPasswordFormContainer button span{
  transform:rotate(90deg);
  font-weight: bold;
}

.ForgotPasswordSubmitButtonContainer {
  margin-top: 20px;
}

.ForgotPasswordInputContainer {
  margin-top: 20px;
}

.ForgotPasswordEmailSent {
  font-family: var(--font-family);
  font-size: 20px;
  color: var(--workspace-primary-color-1);
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 60px;
}

.ForgotPasswordBackButton {
  /* position: absolute;
  top: 60px;
  left: 75px; */
  background: none;
  border: none;
  outline: none;
  padding:0;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: var(--font-family);
  font-size: var(--font-size-3);
  color: var(--neutral-color-1);
  font-weight: normal;
}

.PasswordLogin {
  margin-left: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 15px;
}

.PasswordLogin a {
  font-family: var(--font-family);
  font-size: 14px;
  color: #898989;
  font-weight: bold;
  text-decoration: underline;
  text-align: center;
}

@media only screen and (min-width: 1025px) {
  .ForgotPasswordFormContainer {
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: column;
    height: 90%;
    padding-left: 64px;
    padding-right: 64px;
  }

  .ForgotPasswordErrorContainer {
    margin-top: 1rem;
  }
}
