.GeneralSettingsContainer {
    padding-top: 20px;
}

.GeneralSettingsContainer .InputField {
    margin-bottom: 20px;
}

.GeneralSettingsContainer .ErrorBox {
    margin-bottom: 10px;
}