.Breadcrumbs {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Breadcrumbs div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Breadcrumbs p {
    font-family: var(--font-family);
    font-size: 14px;
    color: var(--workspace-primary-color-1);
    font-weight: normal;
    margin-bottom: 0;
}

.Breadcrumbs img {
    margin-right: 4px;
    margin-left: 4px;
}

.Breadcrumbs .LastItem {
    color: #A4A9AC;
}

.Breadcrumbs a {
    display: flex;
    text-decoration: none;
}