.SignupFormContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  height: 90%;
  padding-left: 1rem;
  padding-right: 1rem;
}

.SignupInputContainer {
  margin-top: 20px;
}

.SignupTermsLink {
  color: var(--workspace-primary-color-3);
}

.SignupCheckboxContainer {
  margin-top: 20px;
}

.SignupSubmitButtonContainer {
  margin-top: 20px;
}

.SignupHaveAccount {
  font-family: var(--font-family);
  font-size: 14px;
  color: #898989;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}

.SignupSignin {
  font-family: var(--font-family);
  font-size: 14px;
  color: #898989;
  font-weight: bold;
  text-decoration: underline;
  margin-left: 4px;
  cursor: pointer;
}

.SignupImage {
  background-size: cover;
}

.SignupImageContainer {
  height: 100vh;
  position: relative;
}

.SignupErrorContainer {
  margin-top: 1rem;
  margin-bottom:1rem;
}

@media only screen and (min-width: 1025px) {
  .SignupFormContainer {
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: column;
    height: 90%;
    padding-left: 64px;
    padding-right: 64px;
  }
}
