.TablePaginationContainer {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    padding-top: 10px;
    border-top: 1px solid #EAEAEA;
}

.TablePaginationContainer .SideButton {
    outline: none;
    text-decoration: none;
    background: none;
	color: #A4A4A4;
	border: none;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: normal;
}

.TablePaginationContainer .NumberButton {
    outline: none;
    text-decoration: none;
    background: none;
	color: var(--neutral-color-1);
	border: none;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: normal;
    color: #A4A4A4;
}

.TablePaginationContainer .Active {
    color: #000000;
}