.ConfirmEmailErrorContainer {
    margin-top: 1rem;
}

.ConfirmEmailFormContainer {
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: column;
    height: 90%;
    padding:1rem 1rem 2rem 1rem;
}

.ConfirmEmailContainer {
    margin-top: 20px;
}

.ConfirmEmailFormContainer button span{
    transform:rotate(90deg);
    font-weight: bold;
  }

.ConfirmEmailSubmitButtonContainer {
    margin-top: 20px;
}

.ConfirmEmailInputContainer {
    margin-top: 20px;
}

.EmailBackButton {
    /* position: absolute;
    top: 60px;
    left: 75px; */
    background: none;
    border: none;
    outline: none;
    padding: 0;
    display: flex;
    margin-bottom: 1rem;
    flex-direction: row;
    align-items: center;
    font-family: var(--font-family);
    font-size: var(--font-size-3);
    color: var(--neutral-color-1);
    font-weight: normal;
}

.EmailBackButton img {
    margin-right: 5px;
}

@media only screen and (min-width: 1025px) {
    .ConfirmEmailFormContainer {
        display: flex;
        flex: 1;
        justify-content: center;
        flex-direction: column;
        height: 90%;
        padding-left: 64px;
        padding-right: 64px;
    }
  }
  

