
.ColorInputContainer {
    background: #e9e9e9;
    margin-bottom: 1rem;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    width: 200px;
    justify-content: center;
    align-items: center;
}

.ColorInputContainer .ColorInputClose {
    align-self: flex-end;
    padding: 0.5rem;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.ColorInputContainer .ColorInputClose:hover {
    cursor: pointer;
    opacity: .5;
}

.ColorInputContainer .react-colorful {
    margin-bottom:0
}