.FilterButtonContainer {
    background-color: var(--neutral-color-9);
    border: none;
    outline: none;
    border-radius: 50px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    color: var(--neutral-color-6);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.FilterButtonContainer:hover,.active-filter .FilterButtonContainer {
    background-color: var(--workspace-primary-color-3);
    color:var(--neutral-color-10);
}

.FilterButtonContainer img {
    margin-right: 10px;
    width: 15px;
    height: 15px;
}

.FilterButtonContainer span {
    font-family: var(--font-family);
    font-size: var(--font-size-2);
    font-weight: normal;
}

.FilterButtonContainer span.icon-typeFilter {
    font-size: var(--font-size-5);
    margin-right:.3rem
}