.SearchInputOuterContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.SearchInputOuterContainer.SmallSearchBox{
  min-width: 0vw;
  display: block;
  border-radius: 0px;
}

.SearchInputContainer {
  background-color: var(--neutral-color-9);
  border-radius: 50px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border:2px solid transparent;
}

.SearchInputContainer.SmallSearchBox {
  border-radius: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 5px;
}

.SearchInputContainer:focus-within {
    background-color: var(--neutral-color-7);
    border:2px solid var(--neutral-color-6);
}

.SearchInputContainer input {
  border: none;
  padding: 0;
  font-family: var(--font-family);
  font-size: 14px;
  color: var(--neutral-color-1);
  font-weight: normal;
  background: none;
  outline: none;
  min-width: 20vw;
}

.SearchInputOuterContainer.SmallSearchBox input{
  min-width: 0vw;
}

.SearchInputContainer input::placeholder {
  color: var(--neutral-color-5);
}

.SearchInputContainer input:focus {
  border: none;
  outline: none;
}

.SearchInputOuterContainer .ClearIcon {
  padding-right: 5px;
  cursor: pointer;
  color: var(--neutral-color-1);
}