.AddAcknowledgmentContainer .Title {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: bold;
  color: var(--neutral-color-1);
  margin-bottom: 30px;
}

.AddAcknowledgmentContainer .Input {
    margin-bottom: 20px;
}