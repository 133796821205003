.NumbersInputContainer {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
}

.NumbersInputDisabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.NumbersInputTitle {
    font-family: var(--font-family);
    font-size: 12px;
    color: #555555;
    font-weight: bold;
    margin-bottom: 0;
}

.NumbersInputStandart {
    border-bottom: 1px solid #EBEBEB;
    padding-bottom: 5px;
}

.NumbersInputBoxContainer {
    background-color: var(--neutral-color-8);
    padding: 4px;
    border-radius: 3px;
    border: 1px solid var(--neutral-color-8);
}

.NumbersInputContainer input {
    border: none;
    outline: none;
    margin: 0;
    padding-left: 0.5rem;
    background: none;
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: normal;
    color: var(--neutral-color-1);
    width: 100%;
}

.NumbersInputFieldError {
    border-color: var(--error-color-1);
}

.NumbersInputContainer input:disabled {
    background: none;
}

.NumbersInputContainer input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.NumbersInputContainer input[type=number] {
  -moz-appearance: textfield;
}

.NumbersInputContainer button {
    background: none;
    outline: none;
    border: none;
    display: flex;
    padding: 3px;
}

.NumbersInputContainer .ButtonsContainer {
    display: flex;
    flex-direction: column;
}