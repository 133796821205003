.AddVideoSourceStepContent .UploadContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.AddVideoSourceStepContent .MaxFileSize {
    font-family: var(--font-family);
    font-size: 11px;
    font-weight: normal;
    color: var(--neutral-color-5);
    text-align: center;
    white-space: nowrap;
}

.AddVideoSourceStepContent h1 {
    font-family: var(--font-family);
    font-size: 40px;
    font-weight: bold;
    color: var(--neutral-color-1);
}

.AddVideoSourceStepContent h3 {
    font-family: var(--font-family);
    font-size: 20px;
    font-weight: normal;
    color: var(--neutral-color-5);
    margin-top: 25px;
}

.AddVideoSourceStepContent .Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.AddVideoSourceStepContent .CropPlayerContainer {
    width: 50vw;
    position: relative;
}

.AddVideoSourceStepContent .LogoPLaceholder {
    object-fit: contain;
    background-color: var(--workspace-primary-color-2);
    padding-left: 3px;
    padding-right: 3px;
}

.CropPlayerContent {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.AddVideoSourceStepContent .CropHeader {
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: normal;
    color: var(--neutral-color-1);
    text-align: center;
}

.AddVideoSourceStepContent .TimePickersContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    width: 30%;
}

.AddVideoSourceStepContent .UseFullVideoContainer {
    margin-top: 10px;
}

.AddVideoSourceStepContent .UseFullVideoContainer button {
    background: none;
    outline: none;
    border: none;
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: normal;
    color: #9F9F9F;
}

.AddVideoSourceStepContent .Footer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 80%;
    margin-top: 20px;
}