.PlayerBranchOverlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 20px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    backdrop-filter: blur(10px);
    z-index: 3;
}

.LessonIsEmptyContainer {
    position: relative;
    overflow: hidden;
    border-radius: 20px;
    display: flex;
    height: 40vh;
    align-items: center;
    justify-content: center;
}

.LessonIsEmptyContainer h3 {
    font-family: var(--font-family);
    font-size: var(--font-size-h3);
    color: black;
}

.PlayerBranchOverlay::-webkit-scrollbar {
  display: none;
}

.ResultsModalContainer {
    background-color: var(--neutral-color-10);
    max-height: 70vh;
    overflow-y: scroll;
    position: relative;
}

.ResultsCloseButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    outline: none;
    border: none;
}

.VideoPlaybackPlayerContainer {
    position: relative;
}

.VideoPlaybackPlayerContainer .SmallPlayButton {
    position: absolute;
    bottom: 10px;
    left: 10px;
    background: none;
    outline: none;
    border: none;
    background-color: var(--workspace-primary-color-3);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    z-index: 2;
}

.VideoPlaybackPlayerContainer .SmallPlayButton img {
    width: 10px;
    height: 10px;
}