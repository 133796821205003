.DataTable {
    font-family: var(--font-family);
    font-size: 20px;
    font-weight: normal;
    color: var(--neutral-color-5);
    margin-top: 30px;
}

.DataTableText {
    font-family: var(--font-family);
    font-size: 13px;
    font-weight: normal;
    color: var(--neutral-color-1);
    margin-bottom: 0;
    display: inline-block;
    -webkit-line-clamp: 2;
            line-clamp: 2; 
    -webkit-box-orient: vertical;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    text-overflow: ellipsis;
}

.DataTableLink {
    font-family: var(--font-family);
    font-size: 13px;
    font-weight: normal;
    color: #006BB8;
    margin-bottom: 0;
    display: inline-block;
    -webkit-line-clamp: 2;
            line-clamp: 2; 
    -webkit-box-orient: vertical;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    text-decoration: underline;
    cursor: pointer;
}


.ListLearnersContainer .DataTableText {
    max-width:100% !important;
}

.DataTableImageNameContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;
}

.DataTableImageNameContainer img {
    width: 40px;
    height: 25px;
    border-radius: 5px;
    margin-right: 10px;
    object-fit: cover;
}

.DataTableSquareImageNameContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 70%;
}

.DataTableSquareImageNameContainer_tooltip {
    max-width: 100%;
}

.DataTableSquareImageNameContainer_thumb{
    max-width: 40px;
    min-width: 40px;
    width: 100%;
    height: 26px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 5px;
    margin-right: 10px;
    background-color:var(--workspace-primary-color-1);
}


.DataTableSquareImageNameContainer img {
    min-width: 40px;
    width: 40px;
    height: 26px;
    border-radius: 5px;
    margin-right: 10px;
}

.DataTableActionButton {
    background: none;
    outline: none;
    border: none;
    font-family: var(--font-family);
    font-size: 12;
    font-weight: normal;
    padding: 5px;
    margin-left: 5px;
}

.DataTableActionDelete {
    color: var(--error-color-1);
}

.DataTableActionEdit {
    color: var(--workspace-primary-color-3);
}

.DataTableActionDisabled {
    color: #BDBDBD;
}

.rdt_TableHead {
    font-family: var(--font-family);
    font-size: 12px !important;
    font-weight: normal !important;
    color: var(--neutral-color-5) !important;
}

.AddLearnerTable .rdt_TableBody{
    max-height: 350px;
    overflow-y: scroll;
}


.AddLearnerTable .rdt_TableBody::-webkit-scrollbar {
    display: none;
}

.rdt_TableRow {
    border: none;
    border-bottom-width: 0px !important;
}

.rdt_TableHeadRow {
    border-bottom-width: 0px !important;
    min-height: 0px !important;
    padding-bottom: 10px !important;
    padding-top: 10px;
}