.FilterWizardContainer {
    position: absolute;
    width: 30vw;
    max-height: 50vh;
    z-index: 2;
    background-color: white;
    box-shadow: 0 5px 10px 0px rgba(0,0,0,.2);
}

.FilterWizardContainer .BoxHeader{
    padding: .2rem .5rem;
    background: var(--workspace-primary-color-1);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius:5px;
    border-top-right-radius: 5px;
}
.FilterWizardContainer .BoxHeader h3{
    padding: 10px 0px 5px 0px;
    font-family: var(--font-family);
    color: var(--neutral-color-10);
    font-size: 1rem;
    flex-direction: flex-end;
    margin:0;
}

.FilterWizardContainer .BoxHeader img{
    width: 20px;
    height: 20px;
    padding: 5px;
    align-self: center;
}

.FilterWizardContainer .BoxSubHeader{
    display: flex;
    background-color: var(--neutral-color-9);
    flex-direction: row;
    align-items: center;
    padding: .5rem;
    border-bottom: 1px solid #E5E5E5;
}

.FilterWizardContainer .BoxSubHeader h3{
    font-family: var(--font-family);
    font-size: 14px;
    color: #8F8F8F;
    margin-bottom: 0px;
}

.FilterWizardContainer .BoxSubHeader img {
    width: 30px;
    height: 30px;
    padding: 5px;
}

.FilterWizardContainer .BoxContent{
    display: flex;
    flex-direction: column;
    padding: .5rem;   
    font-family: var(--font-family);
    font-size: 1rem;
    color: #212529;
    overflow-y: auto;
    max-height: 35vh;
}

.FilterWizardContainer .BoxContent .BoxContentRow{
    display: flex;
    flex-direction: row;
}

.FilterWizardContainer .BoxContent .BoxContentRow p{
font-family: var(--font-family);
    font-size: 14px;
    font-weight: normal;
    color: var(--neutral-color-1);
    margin-bottom: 0;
}

.FilterWizardContainer .BoxFooter{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border-top: 1px solid #E5E5E5;
    background: var(--neutral-color-9);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.FilterBoxContainer{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content:flex-start;
    gap: 10px;   
    overflow-x: auto;
    flex-wrap: wrap;
    margin-top: 20px;
  }

.FilterBoxContainer .FilterNames{
    background: #D9FFE8;  
    border-radius: 50px;
    align-content: center;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 24px;
    padding-right: 24px;
    font: inherit;
    outline: inherit;
    font-family: var(--font-family);
    font-size: 0.7rem;
    font-weight: normal;
    display: flex;
    flex-direction: row;
    text-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.FilterBoxContainer .FilterNames h3{
    align-self: normal;
    font-family: var(--font-family);
    font-size: 0.8rem;
    margin: 0;
    margin-right: 5px;
}

.FilterBoxContainer .FilterNames img{
    width: 0.8rem;
    height: 0.8rem;
    cursor: pointer;
    align-items: baseline;
}

.FilterWizardContainer .BoxFooter {
    padding: 10px;
}