.PageTitleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.PageTitleContainer h1 {
    font-family: var(--font-family);
    font-weight: bold;
    font-size: var(--font-size-h1);
    color: var(--neutral-color-1);
    margin-bottom: 0;
    margin-left: 10px;
}

.PageTitleContainer img {
    width: 32px;
    height: 32px;
}

.PageTitleSubtitle {
    font-family: var(--font-family);
    font-weight: 200;
    font-size: var(--font-size-4);
    color: var(--neutral-color-4);
    margin-bottom: 0;
}

.PageTitleSubtitleWithoutIcon {
    font-family: var(--font-family);
    font-weight: 200;
    font-size: var(--font-size-4);
    color: var(--neutral-color-4);
    margin-bottom: 0;
    padding-left: 10px;
}