.CourseSubheader {
  background-color: #011e1e;
  padding-top: 16px;
  padding-bottom: 12px;
}

.CourseWelcomeText {
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: bold;
  color: var(--neutral-color-10);
}

.CourseDetailsContainer {
  padding-top: 20px;
  padding-bottom: 1rem;
}

.CourseDetailsContainer .Row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.5rem;
}

.CourseDetailsContainer .LessonRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* TEMPORAL */
.CourseDetailsContainer.row .col-2 {
  margin-top: 3rem;
}

.CourseDetailsContainer .CourseActionCard img {
  max-width: 100%;
}
/* TEMPORAL */

.CourseDetailsContainer .OrganizationBadge {
  margin: 0;
  margin-top: 0.5rem;
}

.CourseDetailsContainer .OrganizationBadge img {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  border: 1px solid var(--neutral-color-1);
  padding: 1px;
  margin-right: 5px;
}

.CourseDetailsContainer .OrganizationBadge p {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: normal;
  color: var(--neutral-color-1);
  margin-bottom: 0;
}

.CourseDetailsContainer .CourseTitle {
  font-family: var(--font-family);
  font-size: 36px;
  font-weight: normal;
  color: var(--neutral-color-1);
  margin: 0;
}

.CourseDetailsContainer .CourseDescription {
  font-family: var(--font-family);
  font-size: 1rem;
  font-weight: normal;
  color: var(--neutral-color-1);
  margin-bottom: 0;
  line-height: 1.5;
}

.CourseDetailsContainer .FoldersHeading {
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: normal;
  color: var(--neutral-color-1);
  margin-bottom: 15px;
  margin-top: 20px;
}

.CourseDetailsContainer .LessonContainer {
  cursor: pointer;
}

.CourseDetailsContainer .LessonContainer .CompletedLessonIndicator {
  margin-left: 5px;
  width: 16px;
  height: 16px;
}

.CourseDetailsContainer .LessonContainer p {
  margin-bottom: 0;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: normal;
  color: var(--neutral-color-1);
  margin-left: 5px;
}

.CourseDetailsContainer .LessonContainer {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.CourseDetailsContainer .DisabledLesson {
  cursor: not-allowed;
}

.CourseStats {
  padding-top: 2px;
  padding-bottom: 12px;
}

.CourseStats span {
  margin-left: 5px;
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: normal;
  color: #817d7d;
}

.CourseTitleContainer {
  margin-top: 1rem;
}

.CourseTitleContainer .MaxCharacters {
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: normal;
  color: #817d7d;
}

.CourseTitleContainer button {
  outline: none;
  background: none;
  border: none;
}
.CourseTitleContainer button span {
  display: block;
  color: #1c7777;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.EditButton123 {
  outline: none;
  background: none;
  border: none;
}
.EditButton123 span {
  display: block;
  color: #1c7777;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.CourseTitleContainer input {
  border: none;
  border-bottom: 1px solid var(--neutral-color-1);
  font-family: var(--font-family);
  font-size: 36px;
  font-weight: normal;
  color: var(--neutral-color-1);
  outline: none;
}

.CourseDescriptionContainer button {
  outline: none;
  background: none;
  border: none;
}

.CourseDescriptionContainer button span {
  display: block;
  color: #1c7777;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.CourseDescriptionContainer button span:hover,
.CourseTitleContainer button span:hover {
  cursor: pointer;
  opacity: 0.5;
  transform: rotate(10deg);
}

.CourseDescriptionContainer textarea {
  border: none;
  border-bottom: 1px solid var(--neutral-color-1);
  font-family: var(--font-family);
  font-size: 0.9rem;
  font-weight: normal;
  color: var(--neutral-color-1);
  outline: none;
  width: 100%;
  min-height: 240px;
}

.CourseActionCard {
  padding: 18px;
  border: 1px solid var(--neutral-color-9);
  border-radius: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.CourseActionCard .Poster {
  max-height: 30vh;
  object-fit: cover;
  width: 100%;
}

.CourseActionCard p {
  font-family: var(--font-family);
  font-size: 1.4rem;
  font-weight: normal;
  margin-top: 0.5rem;
  color: var(--neutral-color-1);
  margin-bottom: 0;
}

.CourseActionCard .CourseActionInfo {
  display: flex;
  flex-direction: row;
  justify-content: center;
}


.CourseActionCard .CourseActionInfo img {
  margin-bottom: 3px;
}

.CourseActionCard .Info {
  font-family: var(--font-family);
  font-size: 1rem;
  font-weight: normal;
  color: var(--neutral-color-1);
}

.CourseActionCard .ContinueLearning {
  margin-top: 0.5rem;
}

.CompletedCourse {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}

.CompletedCourse p {
  margin-bottom: 0;
}

.CompletedCourse img {
  margin-right: 10px;
}

.CourseDetailsContainer .FoldersHeadingContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.CourseDetailsContainer .FoldersHeadingContainer button {
  outline: none;
  background: none;
  border: none;
}

.CourseDetailsContainer .FoldersHeadingContainer button span {
  display: block;
  color: #1c7777;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.CourseDetailsContainer .FoldersHeadingContainer button:hover span {
  cursor: pointer;
  opacity: 0.5;
  transform: rotate(10deg);
}

.EditPosterHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
}

.EditPosterHeader h3 {
    font-family: var(--font-family);
    font-size: 20px;
    font-weight: bold;
    color: var(--neutral-color-1);
    margin-bottom: 40px;
}

.EditPosterFooter {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
  padding-top: 10px;
}

.EditPosterFooter .PosterClose {
  margin-right: 10px;
}

@media only screen and (max-width: 767px) {
  .accordion-title{
    max-width: 90px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
  }

  .accordion-header button{
    text-wrap: nowrap;
    font-size: .9rem;
  }
}
