.FreeformOverlayQuestionHeader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 63%, rgba(0,212,255,0) 100%);
    padding-top: 15px;
    padding-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
}

.FreeformOverlayQuestionHeader p {
    font-family: var(--font-family);
    font-size: calc(100vh / 40);
    font-weight: normal;
    margin-bottom: 0;
    color: var(--neutral-color-10);
}

.CollapseFreeformHeaderButton {
    background: var(--workspace-secondary-color-1);
    outline: none;
    border: none;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    transform: rotate(180deg);
}

.CollapseFreeformHeaderButton span {
    color: var(--workspace-primary-color-1);
}

.ExpandFreeformHeaderButton {
    background: var(--workspace-secondary-color-1);
    outline: none;
    border: none;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    position: absolute;
    top: 15px;
    right: 15px;
}

.ExpandFreeformHeaderButton span {
    color: var(--workspace-primary-color-1);
}