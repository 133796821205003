.DatePickerContainer {
    appearance: none;
    outline: none;
}

.DatePickerContainer .react-datepicker-wrapper {
    width: 100%;
}

.DatePickerContainer h3{
    font-family: var(--font-family);
    font-size: var(--font-size-2);
    color: #555555;
    font-weight: bold;
    margin-bottom: 8px; 
}

.DatePickerContainer .DueDate{
    display: flex;
    flex-direction: row;
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: normal;    
    border: none;
    border-bottom: 1px solid #efeae7;
    width: 100%;
    padding-left: 20px;
    padding-bottom: 15px;
    padding-top: 15px;
    background: none;    
    appearance: none;
    outline: none;
}

.DatePickerContainer .DueDateBox {
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    border: none;
    background-color: var(--neutral-color-8);
    border-radius: 3px;
    font-family: var(--font-family);
    font-size: 12px;
    color: var(--workspace-primary-color-1);
    font-weight: normal;
    padding: 8px;
    width: 100%;
    margin-top: 5px;
}

.DatePickerContainer input{
   color: var(--neutral-color-1);
   background-color: var(--neutral-color-8);
}

.DatePickerContainer .react-datepicker{
    background-color: var(--neutral-color-8);
    border: none;
    padding: none;
    font-family: var(--font-family);
    font-size: 14px;
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    /* box-shadow: 0px 0px 15px #80808066; */
}
.DatePickerContainer .react-datepicker__month{
    background-color: var(--neutral-color-8);
    padding: none;
    margin: 0.2em 1em;
}
.DatePickerContainer .react-datepicker__header{
    border: none;
    background: var(--neutral-color-8);
    font-family: var(--font-family);
    font-size: 14px;
    color: var(--neutral-color-1);
    width: 100%;
}

.DatePickerContainer .react-datepicker__month-select, .react-datepicker__year-select{
    border-radius: 5px;
    border: 1px solid #E1E1E1;
    color: #A4A9AC;
}

.DatePickerContainer .react-datepicker__day--selected {
    background-color: var(--workspace-secondary-color-1);
}

.DatePickerContainer .react-datepicker__month-container{
    background: var(--neutral-color-8);
    height: auto;    
}


.DatePickerContainer .react-datepicker__week {
    height: 1.8em;     
  }
  
.DatePickerContainer .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    width: 2.5em;
    line-height: 1.0em; 
    font-family: var(--font-family);
    font-size: 14px;
    color: #000000;
  }

  .DatePickerContainer .react-datepicker__day--disabled{
    color: #9C9C9C;
  }

  .DatePickerContainer .react-datepicker__current-month {
    display: none;
}

.DatePickerContainer .react-datepicker__day-names {
    margin-top: 10px;
}

.DatePickerContainer .react-datepicker__month-select {
    padding: 2px;
}

.DatePickerContainer .react-datepicker__year-select {
    padding: 2px;
}

.DatePickerContainer .react-datepicker__header__dropdown {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.DatePickerContainer .react-datepicker__navigation {
    display: none;
}