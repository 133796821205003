.RetryButton {
    font-family: var(--font-family);
    font-size: 14px;
    color: #898989;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
    cursor: pointer;
    border: none;
    outline: none;
    background: none;
}