[data-column-id="type"].rdt_TableCol_Sortable {
    text-align: center;
    width: 100%;
    justify-content: center;
    
}

[data-column-id="type"].rdt_TableCell {
    text-align: center;
    width: 100%;
    justify-content: center;
}

[data-column-id="status"].rdt_TableCol_Sortable {
    text-align: center;
    width: 100%;
    justify-content: center;   
    padding-left: 5px;
}

[data-column-id="status"].rdt_TableCell {
    text-align: center;
    width: 100%;
    justify-content: center;
}

[data-column-id="actions"].rdt_TableCol {
    text-align: center;
    width: 100%;
    justify-content: center;
    padding-left: 10px;
}

[data-column-id="actions"].rdt_TableCell {
    text-align: center;
    width: 100%;
    justify-content: center;
}

.StaffListFooter {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 25px;
}

.StaffInviteContainer {
    padding-top: 20px;
}

.StaffInviteContainer h2 {
    font-family: var(--font-family);
    font-size: 20px;
    color: var(--neutral-color-1);
    margin-bottom: 4px;
    font-weight: normal;
}

.StaffInviteContainer h5 {
    font-family: var(--font-family);
    font-size: 16px;
    color: #A4A9AC;
    font-weight: 200;
}

.StaffInviteContainer .NameInput {
    margin-top: 20px;
    margin-bottom: 30px;
}

.StaffInviteContainer .Footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 30px;
}

.StaffInviteContainer .Footer .Cancel {
    margin-right: 10px;
}

.StaffInviteContainer .ErrorBox {
    margin-top: 20px;
}

.StaffEditSelect {
    margin-top: 40px;
}

.DataTable .ActivateContainer span {
    color: var(--workspace-primary-color-3);
    font-size: 16px;
}