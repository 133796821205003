.UploadPPTButtonContainer{
    display: flex;
    flex-direction: row;    
    margin-bottom: 15px;
    width: 90%;
    font-family: var(--font-family);
    font-size: 14px;
    color: #555555;
    font-weight: normal;    
    align-items: center;
}

.PPTContainer .Heading{
    display: flex;
    flex-direction: column;
    justify-content: center;    
    margin-bottom: 10px;
}

.PPTContainer p{
    font-family: var(--font-family);
    font-size: var(--font-size-2);
    color: #555555;
    font-weight: bold;
    margin-bottom: 5px;

}

.PPTContainer .SubtitleText{
    font-family: var(--font-family);
    font-size: 10px;
    font-weight: normal;
    color: var(--neutral-color-5);       
}

.UploadPPTButtonContainer button {
    background-color: var(--workspace-primary-color-1);
    color: var(--workspace-secondary-color-1);
    border-radius: 50px;
	border: none;
	padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 24px;
    padding-right: 24px;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    font-family: var(--font-family);
    font-size: var(--font-size-2);
    font-weight: normal;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 35px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.UploadPPTButtonContainer button:hover {
	background-color: var(--workspace-primary-color-4);
    color: var(--workspace-secondary-color-1);
}

.UploadPPTButtonContainer img {
    vertical-align: middle;
    margin-right: 10px;
}

.UploadPPTButtonContainerDisabled button {
    border: 1px solid #E2E2E2;
    background-color: #F6F6F680;
    color: #97979780;
    cursor: not-allowed;
    justify-content: center;
}

.UploadPPTButtonContainer .UploadedFileTitle{
    padding-top: 10px;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: 1px solid #EBEBEB;
    cursor: pointer;
    color: var(--neutral-color-1);
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    width:100%;
}