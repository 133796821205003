.FoldersRoot {
  height: 100%;
}

.FoldersList {
  position: relative;
}

.FoldersList .Item {
  position: absolute;
}

.FolderCard {
  background-color: var(--neutral-color-10);
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  border: 1px solid #d3d2d2;
  box-shadow: 0px 1px 1px 1px var(--neutral-color-9);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.FolderCard:hover {
  border-color: #1d77774d;
  box-shadow: 0px 3px 4px 1px #c4c4c4;
}

.FolderCard .ImageContainer .Poster {
  max-width: 100%;
  max-height: 100%;
  width: 10vw;
  height: 10vh;
  object-fit: contain;
}

.FolderCardSelected {
  border: 2px solid var(--workspace-primary-color-3);
}

.FolderCard p {
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: normal;
  color: var(--neutral-color-1);
  margin-bottom: 5px;
  text-align: center;
  max-width: 135px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.FolderCard .ImageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f8f8f8;
  width: 135px;
  height: 90px;
}

.FolderCard .DragIconContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 2px;
  padding-bottom: 2px;
}

.FolderCardArrowContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 5px;
  margin-top: 5px;
}

.FolderOptions .OptionsSection {
  margin-top: 20px;
}
