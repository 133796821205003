.DropdownContainer {
  position: relative;
}

.DropdownDisabled {
  opacity: 0.4;
  cursor: not-allowed !important;
}

.DropdownBody {
  padding-top: 10px;
  padding-bottom: 15px;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid #EBEBEB;
  cursor: pointer;
  color: var(--neutral-color-1);
  font-family: var(--font-family);
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.DropdownBodyBox {
  padding: 8px;
  cursor: pointer;
  color: var(--neutral-color-1);
  font-family: var(--font-family);
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--neutral-color-8);
  border-radius: 3px;
  margin-top: 5px;
}

.DropdownContainer .ChevronOpen {
  transform: rotate(180deg);
}

.DropdownContainer .Placeholder {
  color: var(--neutral-color-1);
  opacity: 50%;
}

.DropdownContainer .FieldError {
  border-bottom: 1px solid var(--error-color-1);
}

.DropdownList {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 10px;
  max-height: 200px;
  overflow-y: auto;
  font-family: var(--font-family);
  font-size: 12px;
  color: var(--neutral-color-1);
  font-weight: normal;
  position: absolute;
  top: 100%;
  width: 100%;
  left: 0;
  z-index: 10;
  border: 1px solid #E1E1E1;
  border-radius: 5px;
  box-sizing: border-box;
  overflow-x: hidden;
  background-color: var(--neutral-color-10);
}

.DropdownContainer .ListItem {
  padding: 10px;
  cursor: pointer;
}

.DropdownContainer .ListItem:hover {
  background-color: #E6E6E6;
}

.DropdownTitle {
  font-family: var(--font-family);
  font-size: 12px;
  color: #555555;
  font-weight: bold;
  margin-bottom: 0;
}


.DropdownSubtitleText{
  font-family: var(--font-family);
  font-size: 10px;
  font-weight: normal;
  color: var(--neutral-color-5);      
}


/*new css*/
