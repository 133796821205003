.StorageSettingsContainer {
    padding-top: 20px;
}

.StoragePrompt {
    font-size: var(--font-size-h5);
    font-family: var(--font-family);
    color: var(--neutral-color-1);
    font-weight: 200;
}

.VimeoSetupButton {
    margin-top: 20px;
}

.StoragePrompt span {
    color: #B8EF36;
}