.DashboardRoot {
  height: 100vh;
  overflow-y: hidden;
}

.DashboardRootSideNav {
  background-color: #e7ecef;
  height: 100%;
  padding: 0 !important;
}

.DashboardRootContent {
  overflow-y: auto;
}

.DashboardRootSideNav .NavItemsList {
  margin-top: 36px;
}

.DashboardRootSideNav .NavListItemContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.DashboardRootSideNav .NavListItemContainer .MainRouteLink:hover {
  background-color: var(--workspace-primary-color-3);
}
.DashboardRootSideNav .NavListItemContainer .MainRouteLink:hover span,
.DashboardRootSideNav .NavListItemContainer .MainRouteLink:hover h4 {
  color: var(--neutral-color-10);
}

.DashboardRootSideNav .NavListItemContainer .MainRouteLink {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 1rem 1.5rem;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.DashboardRootSideNav .NavListItemContainer a:not(.MainRouteLink) {
  position: absolute;
  right: 0;
  padding: 0.5rem;
  text-decoration: none;
  top: 6px;
}

.DashboardRootSideNav .NavListItemContainer a:not(.MainRouteLink) span{
background-color: var(--neutral-color-4);
border-radius: 50%;
display: flex;
justify-content: center;
align-items: center;
width: 20px;
height: 20px;
}

.DashboardRootSideNav .NavListItemContainer a:not(.MainRouteLink) span:hover{
background-color: var(--workspace-primary-color-3);
}

.DashboardRootSideNav .NavListItemContainer a:not(.MainRouteLink) span::before{
color: var(--neutral-color-10);
font-size: var(--font-size-1);
}

.DashboardRootSideNav .NavListItemContainer a:not(.MainRouteLink) img {
  display: block;
}

.DashboardRootSideNav .NavListItemContainer .MainRouteLink h4 {
  font-family: var(--font-family);
  font-weight: normal;
  font-size: 14px;
  margin-bottom: 0;
  margin-left: 10px;
  color: #979797;
}

.DashboardRootSideNav .NavListItemContainer .MainRouteLink span {
  color: var(--workspace-primary-color-1);
}

.DashboardRootSideNav .NavListItemContainer.activeRouteLink {
  background-color: var(--workspace-primary-color-3);
}

.DashboardRootSideNav .NavListItemContainer.activeRouteLink span,
.DashboardRootSideNav .NavListItemContainer.activeRouteLink h4 {
  color: var(--neutral-color-10);
}

.DashboardRootSideNav .NavItemsList .Header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  margin-bottom: 30px;
}

.DashboardRootSideNav .NavItemsList .Header img {
  width: 27px;
  height: 27px;
  border-radius: 28px;
  margin-right: 5px;
}

.DashboardRootSideNav .NavItemsList .Header span {
  font-family: var(--font-family);
  font-weight: bold;
  font-size: 16px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.DashboardRootSideNav .Divider {
  margin-top: 30px;
  border-top: 1px solid #d5dbdf;
  margin-bottom: 15px;
  margin-left: 10px;
  margin-right: 10px;
}

.QuotaLimitContainer {
  padding: 0.5rem 1.5rem;
  margin-top: 10px;
}

.QuotaLimitContainer .Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}

.QuotaLimitContainer .Header p {
  font-family: var(--font-family);
  font-weight: normal;
  font-size: 14px;
  color: var(--workspace-primary-color-1);
  margin-bottom: 0;
}

.QuotaLimitContainer .Underline {
  position: relative;
  width: 100%;
  height: 3px;
  background-color: #8f8f8f;
  border-radius: 5px;
}

.QuotaLimitContainer .Inline {
  position: absolute;
  top: 0;
  left: 0;
  width: 25%;
  height: 3px;
  background-color: var(--workspace-secondary-color-1);
  border-radius: 5px;
}

.QuotaLimitContainer .FooterText {
  font-family: var(--font-family);
  font-weight: normal;
  font-size: 12px;
  color: var(--workspace-primary-color-1);
  margin-bottom: 0;
}

.QuotaLimitContainer .Footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.QuotaLimitContainer .Footer button {
  background: none;
  border: none;
  outline: none;
  color: var(--workspace-primary-color-3);
  font-family: var(--font-family);
  font-weight: normal;
  font-size: 12px;
  padding: 0;
}
