.SettingsUserProfileContainer {
  padding: 2rem;
  min-height: 50vh;
}

.SettingsUserProfileContainer p {
  font-family: var(--font-family);
  font-size: var(--font-size-2);
  color: #555555;
  font-weight: bold;
  margin-bottom: 10px;
}

.SettingsInput {
  margin-bottom: 5%;
}

.SettingsUserProfileContainer button {
  border: none;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-right: 10px;
  padding-left: 10px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  border-radius: 50px;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 0.8rem;
  text-decoration: none;
  color: var(--neutral-color-10);
  background-color: var(--workspace-primary-color-3);
  border: 0.5px solid #cbd1d8;
  min-width: 5px;
  margin-top: 5%;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.SettingsUserProfileContainer button:hover {
  color: var(--neutral-color-10);
  background-color: var(--workspace-primary-color-2);
}

.PasswordContainer {
  display: flex;
  flex-direction: column;
  padding: 10px 0 10px 35px;
  justify-content: left;
  width: 100%;
}

.SettingsUserProfileContainer .PasswordInputEye {
  height: 25px;
  width: 25px;
}

/* .SettingsUserProfileContainer p {
    font-family: var(--font-family);
      font-weight:500;
      font-size: 0.8rem;
      color:var(--neutral-color-1);
  } */

.SettingsUserProfileContainer img {
  border: var(--neutral-color-1);
}

.SettingsUserProfileContainer .ForgotPasswordInputContainer .PasswordInputContainer {
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #e7ecef;
  border-radius: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: transparent;
  border-bottom: 1px solid #efeae7;
  border-radius: 0;
}
.SettingsUserProfileContainer .ForgotPasswordInputContainer .PasswordInputContainer .PasswordInputInput {
  appearance: none;
  background-color: initial;
  border: none;
  color: #011e1e;
  font-family: Walsheim;
  font-size: 14px;
  font-weight: 400;
  outline: none;
  width: 100%;
}
.SettingsUserProfileContainer .ForgotPasswordInputContainer .PasswordInputContainer .PasswordInputInput::placeholder {
    color: var(--neutral-color-6);
}





@media only screen and (min-width: 1025px) {
  .SettingsUserProfileContainer {
    padding: 2rem 13rem 2rem 3rem;
  }
}
