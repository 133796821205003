.UploadVideoButtonContainer button {
    background-color: var(--workspace-primary-color-1);
    color: var(--workspace-secondary-color-1);
    border-radius: 50px;
	border: none;
	padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 24px;
    padding-right: 24px;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    font-family: var(--font-family);
    font-size: var(--font-size-2);
    font-weight: normal;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 35px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.UploadVideoButtonContainer button:hover {
	background-color: var(--workspace-primary-color-4);
    color: var(--workspace-secondary-color-1);
}

.UploadVideoButtonContainer img {
    vertical-align: middle;
    margin-right: 10px;
}

.UploadVideoButtonContainerDisabled button {
    border: 1px solid #E2E2E2;
    background-color: #F6F6F680;
    color: #97979780;
    cursor: not-allowed;
    justify-content: center;
}