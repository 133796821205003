.NotFoundContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10vh;
}

.NotFoundContainer img {
    max-width: 100%;
}

.NotFoundContainer h3 {
    font-family: var(--font-family);
    font-size: 20px;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    margin-top: 30px;
    margin-bottom: 20px;
    max-width: 80%;
    text-align: center;
}

.NotFoundContainer span {
    font-family: var(--font-family);
    font-size: 16px;
    font-style: normal;
    font-weight: 200;
    line-height: 24px;
    text-align: center;
    max-width: 70%;
}

.NotFoundContainer .Links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.NotFoundContainer a {
    color: #056260;
    text-align: center;
    font-family: var(--font-family);
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    padding: 10px;
    text-decoration: none;
}