.LtiSettingsContainer {
    padding-top: 20px;
}

.LtiSettingsContainer .LtiToolTitle {
    font-size: var(--font-size-h5);
    font-family: var(--font-family);
    color: var(--neutral-color-1);
    font-weight: bold;
}

.LtiSettingsContainer .PlatformsContainer {
    margin-top: 20px;
}

.LtiSettingsContainer .NoPlatforms {
    font-size: var(--font-size-h5);
    font-family: var(--font-family);
    color: var(--neutral-color-4);
    font-weight: 200;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.LtiSettingsContainer .AddPlatfromButton {
    margin-top: 20px;
}

.LtiPrompt {
    font-size: var(--font-size-h5);
    font-family: var(--font-family);
    color: var(--neutral-color-1);
    font-weight: 200;
}

.AddPlatformModal .Title {
    font-size: var(--font-size-h3);
    font-family: var(--font-family);
    color: var(--neutral-color-1);
    font-weight: bold;
}

.AddPlatformModal .LTIFormInput {
    margin-top: 20px;
}

.AddPlatformModal .Footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 20px;
}
