.RadioGroupOption {
    border: none;
    outline: none;
    background: none;
    display: block;
    padding: 6px;
}

.RadioGroupOption .Subtitle {
    font-family: var(--font-family);
    font-size: 14px;
    color: #9E9E9E;
    font-weight: normal;
    margin-bottom: 0;
    text-align: start;
}

.RadioGroupOption .OptionCircle {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    border: 1px solid #9E9E9E;
    display: inline-block;
    margin-right: 8px;
    padding: 1px;
}

.RadioGroupOption .OptionSelcted {
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background-color: #23CE2a;
}

.RadioGroupOption .RadioButton {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.RadioGroupOption .RadioButton p {
    font-family: var(--font-family);
    font-size: 14px;
    color: var(--neutral-color-1);
    font-weight: normal;
    margin-bottom: 0;
}