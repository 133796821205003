.WorkspaceContainer {
    padding-top: 20px;
}

.SubscriptionStartContainer {
    background-color: var(--neutral-color-9);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 4rem;
    padding-bottom: 4rem;
    margin-top: 30px;
}

.SubscriptionStartContainer img {
    width: 15%;
}

.SubscriptionStartContainer h1 {
    font-family: var(--font-family);
    font-size: var(--font-size-h1);
    font-weight: bold;
    margin-top: 15px;
}

.SubscriptionStartContainer p {
    font-family: var(--font-family);
    font-size: var(--font-size-h4);
    font-weight: normal;
    margin-bottom: 3px;
}

.SubscriptionStartContainer .StartTrialButton {
    margin-top: 15px;
}

.SubscriptionStartContainer .ClickToUpgrade {
    font-family: var(--font-family);
    font-size: var(--font-size-paragraph);
    font-weight: 200;
    margin-top: 20px;
}