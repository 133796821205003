.MyLibraryModalContainer h1 {
    font-family: var(--font-family);
    font-size: 40px;
    font-weight: bold;
    color: var(--neutral-color-1);
}

.MyLibraryModalContainer h3 {
    font-family: var(--font-family);
    font-size: 20px;
    font-weight: normal;
    color: var(--neutral-color-5);
    margin-top: 25px;
}

.MyLibraryModalContainer .Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.MyLibraryModalContainer .PlaceholderLogo {
    object-fit: contain;
    background-color: var(--workspace-primary-color-2);
    padding-left: 3px;
    padding-right: 3px;
}